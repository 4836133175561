/* eslint-disable no-console */
import {generateUUID} from '../../app.helpers';
import { EventHubProducerClient } from '@azure/event-hubs';

// Assets
import DeviceInfo from './deviceInfo';
import * as LocalStorage from './local-storage';
import * as GameWrapper from './game-wrapper';
import { readDeepLinkStorage } from './local-storage';
import { versionGreaterThanOrEqual } from './utils';

export const LOG_LEVEL = {
    /*@deprecated - Be aware that FATAL messages will make the Native Client V2 crash, so it must only be used when there’s a situation that prevents the Client from working at all*/
    FATAL: 0,
    ERROR: 1,
    WARN: 2,
    INFO: 3,
    DEBUG: 4,
    TRACE: 5
};

export const LOG_TYPE = {
    SESSION_REQUEST:'SESSION_REQUEST',
    SESSION_RESPONSE:'SESSION_RESPONSE',
    UNHANDLED_ERROR:'UNHANDLED_ERROR',
};

let elasticInstance = null;
class Elastic {
    constructor() {
        this.envLogLevel = LOG_LEVEL[window.config.REACT_APP_LOG_LEVEL];
        this.prevRequestId = null;

        this.azureEventHubsConnectionString = null;
        this.azureEventHubsName = null;
        this.buildNumber = null;
        this.buildHash = null;
        this.environment = null;
        this.service = null;
        this.region = null;

        this.logs = [];
    }

    set = ({ azureEventHubsConnectionString, azureEventHubsName, buildNumber, buildHash, environment, service, region }) => {
        this.azureEventHubsConnectionString = azureEventHubsConnectionString;
        this.azureEventHubsName = azureEventHubsName;
        this.buildNumber = buildNumber;
        this.buildHash = buildHash;
        this.environment = environment;
        this.service = service;
        this.region = region;

        this.logs = LocalStorage.readErrorLogs() || [];
    };

    addLogToEventHubBatch = (logLevel, message, noBatch) => {
        const userData = LocalStorage.readUserData() || {};

        // WebRTC doesn't have access to certain data
        this.logs.push({ body: {
            clientrunid: DeviceInfo.clientRunID || '', // non-WebRTC
            deviceid: DeviceInfo.deviceID || '', // non-WebRTC
            sessionid: userData.sessionId,
            userid: userData.userId,
            environment: this.environment,
            region: this.region,
            buildnumber: this.buildNumber,
            buildhash: this.buildHash,
            service: this.service,
            logLevel,
            message,
            realTimestamp: Date.now()
        }});

        LocalStorage.writeErrorLogs(this.logs.slice(-5));

        if (LOG_LEVEL[logLevel] === LOG_LEVEL.ERROR || LOG_LEVEL[logLevel] === LOG_LEVEL.FATAL || this.logs.length >= 10 || noBatch) {
            this.sendLogsWithEventHub();
        }
    };

    // duplicate the above method for backward compatibility reasons.
    // Reason being that addLogToEventHubBatch marshalls the message to JSON regardless the data type,
    // where in our case we want the message to be "merged" into the log object instead.
    addEventToEventHubBatch = (logLevel, event, noBatch) => {
        const userData = LocalStorage.readUserData() || {};

        // WebRTC doesn't have access to certain data
        this.logs.push({ body: {
            sessionid: userData.sessionId,
            userid: userData.userId,
            environment: this.environment,
            region: this.region,
            buildnumber: this.buildNumber,
            buildhash: this.buildHash,
            service: this.service,
            logLevel,
            realTimestamp: Date.now(),
            ...event,
        }});

        LocalStorage.writeErrorLogs(this.logs);

        if (LOG_LEVEL[logLevel] === LOG_LEVEL.ERROR || LOG_LEVEL[logLevel] === LOG_LEVEL.FATAL || this.logs.length >= 10 || noBatch) {
            this.sendLogsWithEventHub();
        }
    };

    sendLogsWithEventHub = async () => {
        // resolve this ERROR on Ultralight: Unhandled Promise Rejection: TypeError: undefined is not an object (evaluating 'globalThis.crypto.subtle.importKey')
        if (!window?.crypto?.subtle) {
            console.warn('Warning! window.crypto.subtle is undefined. sendLogsWithEventHub will not work.');
            return;
        }

        if (!this.logs.length) return;

        const producerClient = new EventHubProducerClient(this.azureEventHubsConnectionString, this.azureEventHubsName);
        const eventDataBatch = await producerClient.createBatch();

        this.logs.forEach(log => {
            let wasAdded = null;
            let addAttempts = 3;

            do {
                wasAdded = eventDataBatch.tryAdd(log);
                addAttempts--;
            } while (!wasAdded || addAttempts <= 0);
        })

        await producerClient.sendBatch(eventDataBatch);
        await producerClient.close();

        this.logs = [];
        LocalStorage.writeErrorLogs([]);
    };

    getLogLevelString = (logLevel) => {
        return Object.keys(LOG_LEVEL).find((key)=>LOG_LEVEL[key]===logLevel);
    };

    log = (type, dataObject, logLevel) => {
        if(logLevel <= this.envLogLevel) {
            try {
                dataObject.type = type;
                dataObject.browserData = navigator.userAgent;
                const data = JSON.stringify(dataObject);
                this.addLogToEventHubBatch(this.getLogLevelString(logLevel), data);
                // axios.post(window.config.REACT_APP_LOG_ENDPOINT, data);
                // console.log('log',data,'logLevel:',logLevel);
            } catch(e) {
                console.log('JSON ERROR',e);
            }
        }
    };

    logEvent = (logLevel, dataObject) => {
        if(logLevel <= this.envLogLevel) {
            try {
                this.addEventToEventHubBatch(this.getLogLevelString(logLevel), dataObject);
            } catch(e) {
                console.log('JSON ERROR',e);
            }
        }
    }

    isDirectNativeMessagingSupported = () => {
        if (!DeviceInfo.isRunningOnNativeClientV2()) return false;

        return versionGreaterThanOrEqual(DeviceInfo.version, '3.1.0');
    };

    // Don't put meta data or fancy info it there, just log what I tell you!
    logDirect = (logLevel, message, noBatch) => {
        /**
         * If client v2 the UI should always log elastic via the logMessage message, which sends the log message to Native for logging.
         * If client v2 - no need to check log level - Native takes care of that
         **/
        if (this.isDirectNativeMessagingSupported()) {
            GameWrapper.logMessage(message, this.getLogLevelString(logLevel));
            return;
        }

        if(logLevel <= this.envLogLevel) {
            try {
                this.addLogToEventHubBatch(this.getLogLevelString(logLevel), message, noBatch);
            } catch(e) {
                console.log('logging error',e);
            }
        }
    }

    logSessionRequest = (requestData) => {
        if(this.prevRequestId) {
            this.logSessionResponse({timeOut:true});
        }
        const requestId = generateUUID();
        this.prevRequestId = requestId;

        this.log(LOG_TYPE.SESSION_REQUEST,{
            requestId,
            data:requestData,
        }, LOG_LEVEL.INFO);

        return requestId;
    };

    logSessionResponse = (responseData) => {
        if(!this.prevRequestId)return;//this request ignored because of timeout.
        const requestId = this.prevRequestId;
        this.prevRequestId = null;

        this.log(LOG_TYPE.SESSION_RESPONSE, {
            requestId,
            data:responseData,
        }, LOG_LEVEL.INFO);
    };

    logUnHandledError = (error) => {
        this.log(LOG_TYPE.UNHANDLED_ERROR,{
            data:error,
        }, LOG_LEVEL.FATAL);
    };

    logDeviceLink = (userID, deviceID) => {
        if (userID && deviceID)
        {
            const messageData = {
                event : "DEVICELINK",
                UID : userID,
                deviceID : deviceID,
                startupURL : readDeepLinkStorage()
            };
            this.logDirect(LOG_LEVEL.WARN, "ReportingDB Message : reportingDB " + JSON.stringify(messageData) + " reportingDB" );
        }
    }
}

const getElastic = () => {
    if(elasticInstance===null) {
        elasticInstance = new Elastic();

        // Nasty way to force this to the end of the JS event loop,
        // but needs to be done because time investment to fix our flow is too big
        setTimeout(() => GameWrapper.configureLogging())
    }
    return elasticInstance;
};

export default getElastic();
