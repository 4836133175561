import React from 'react';
import { navigateToSavedLocation, navigateToLocation, ROUTES } from '../../app.router';
import { FetchLogosSuccessAction} from '../../entities/logos/logos.actions';
import { FetchBadgesSuccessAction} from '../../entities/badges/badges.actions';
import {FavouritedGamesSuccessAction, FetchHomepageGamesAction} from '../../entities/games/games.actions';
import { FetchDebugSettingsAction, UpdateUserLanguageAction} from '../settings/settings.actions';
import { AddDelayedPopupAction } from '../popup/popup.actions';
import {
	APP_INIT_SUCCESS_ACTION,
	GetIpStackObjectSuccess,
	SaveRoute,
	setConfig,
	setCurrentRegion
} from '../../app.actions';
import {isDeviceWithNativePayments} from '../../app.helpers';
import {addPopup, closePopup} from '../popup/popup.component';
import GenericPopup, { TYPE_HAS_TWO_BUTTONS } from '../popup/generic-popup/generic-popup.component';
import {clearHeaderData} from '../user-header/user-header.component';
import deviceInfo, {
	STORE_TYPE_EPIC,
	STORE_TYPE_MICROSOFT,
	PLATFORM_TYPE_ANDROID,
	PLATFORM_TYPE_NVIDIA_SHIELD,
	PLATFORM_TYPE_MAC,
	PLATFORM_TYPE_WIN_UWP_X64,
	PLATFORM_TYPE_WIN32_X86,
	PLATFORM_TYPE_LINUX,
	PLATFORM_TYPE_ANDROID_SAMSUNG,
	PLATFORM_TYPE_FIRE_TV,
	STORE_TYPE_APPLE_IOS,
	STORE_TYPE_AMAZON, PLATFORM_TYPE_WEB, STORE_TYPE_SAMSUNG_TV,
	PLATFORM_TYPE_IPHONE
} from '../../assets/lib/deviceInfo';
import {getFacebookUserData} from '../../assets/lib/facebook.lib';
import Elastic, {LOG_LEVEL} from '../../assets/lib/elastic.lib';
import {antstreamService, antstreamAPIService} from '../../app.reducer';
import {GetTutorialsCompletedActionSuccess} from '../tutorial/tutorial.actions';
import {FetchUnlockChallengesActionSuccess} from '../../entities/challenges/challenges.actions';
import {FetchMessagesAction} from '../../entities/messages/messages.actions';
import AudioManager,{TYPE_LAUNCH} from '../../app.audio';
import {FetchAvatarsSuccessAction} from '../../entities/avatars/avatars.actions';
import {getAvatarUrl} from '../../entities/entities.selectors';
import {GetPushServiceIdAction} from "../../entities/users/users.actions";
import {getLoggedUser, getUserIsSubscribed} from "../../app.selectors";
import {getLoginError, getWaysunUserId} from "./login.selectors";
import {
	consoleLogWithTimestamp,
	getBrowserLanguage,
	handleNoAuthTokenIssue,
	isDelayedPage,
	isFreeTierV2FeatureFlagEnabled, isFreeTrialSubscription,
	isXboxUser
} from '../../assets/lib/utils';
import { tryDecodeDeepLinkAndNavigate } from '../../assets/lib/deepLinkGenerator';
import {clearAll, readRestUserData} from '../../assets/lib/local-storage';
import {webSocketErrorHandling} from '../../assets/lib/ErrorTypes';
import {ShowNotificationAction} from '../notifications/notifications.actions';
import KeepAlive from '../../assets/lib/KeepAlive';
import {
	clearLoginCredentials,
	startNativePayment,
	messageNativeMicrosoftProductListings,
	messageNativeXboxRetryLogin
} from '../../assets/lib/game-wrapper';
import { launchBgService } from '../../assets/lib/tizen';

// Components
import ShopResultPopup from '../popup/shop-result-popup/shop-result-popup.component';
import SubscriptionComparisonsPopup from "../popup/subscription-comparisons-popup/subscription-comparisons-popup.component";
import XboxSubscriptionPopup, {popupType as xboxPopupType} from "../popup/xbox-subscription-popup/xbox-subscription-popup.component";
import { APPLE_IOS_SKU, PREMIUM_POPUP_HEADER_TEXT_3 } from '../../constants';
import { IosWelcomePopup } from '../popup/ios-welcome-popup/index.component';

// Assets
import * as RestAPI from '../../assets/lib/RestAPI';
import * as EpicGames from '../../assets/lib/epic-games.lib';
import * as GameWrapper from '../../assets/lib/game-wrapper';
import * as LocalStorage from '../../assets/lib/local-storage';

const RESET_PASSWORD_MESSAGE = 'Password expired';
export const LOGIN_USER_ACTION = 'Login With Password Action';

export const LoginUserAction = ({ email, password, promoCode }, isAutoLogin = false, isSignUp = false) => {
    return dispatch => {
        dispatch({ type: LOGIN_USER_ACTION });

            const payload = {
                provider: 'USER',
                operation: 'login',
                data: {
                    userName: email,
                    password
                }
            };
            antstreamAPIService.login
                .loginCreate(payload)
                .then(async res => {
                    const resp = res.data;
                    if (resp.error) throw new Error(resp.error);

					const { accessToken, userId } = resp.data;

					LocalStorage.writeRestUserData({
						email,
						authToken: accessToken,
						userId: userId
					});

					if(deviceInfo.storeType == STORE_TYPE_SAMSUNG_TV){
						// import ('../../assets/lib/tizen').then(exports => {
						launchBgService(accessToken);
					// });
					}

					dispatch(GetAccountDetailsAction(promoCode, isAutoLogin, isSignUp));
                })
                .catch(loginErr => {
                    dispatch(LoginUserFailAction(loginErr.message));

                    // SHOW error popup
					if (loginErr.message === 'Wrong password') {
						webSocketErrorHandling({ DETAILS: 'UNRECOGNISED' });
                    } else if (loginErr.message === RESET_PASSWORD_MESSAGE) { // special case for update old gamesparks password
						addPopup(<GenericPopup
							okButtonLabel="Got it!"
							title="Password Expired"
							message={'Your password needs updating to proceed. We have sent you a unique token code via an email.'}
						/>, {}, true);

						dispatch(SetSignupFieldAction({email}));
						dispatch(RequestPasswordResetAction(email));
						return;
                    } else {
                        webSocketErrorHandling({ DETAILS: loginErr.message });
                    }

                    LocalStorage.clearAll('LoginUserAction');
                    if (isAutoLogin) navigateToLocation(ROUTES.HOMEPAGE);
                });
    };
};

export const GetAccountDetailsAction = (
		promoCode = null,
		isAutoLogin = false,
		isSignUp = false,
		needXboxRetryMessage = false
	) => {
	return (dispatch, getState) => {
			const {authToken} = readRestUserData();
			antstreamAPIService.accountDetails.accountDetailsList({
				headers: {'Authorization': "Bearer " + authToken}
			}).then(res => {
				consoleLogWithTimestamp('GetAccountDetailsAction finished.');

				const resp = res.data;
				if (!resp.error) {
					if (deviceInfo.isXboxPlatform()) {
						console.log('Platform login xboxLive GetAccountDetailsAction success. ' + resp.data.scriptData?.user?._id);
					}
					if (deviceInfo.isPlayStationPlatform()) {
						console.log('Platform login playStation GetAccountDetailsAction success. ' + resp.data.scriptData?.user?._id);
					}
					dispatch(LoginUserSuccessAction(resp.data.scriptData));
					if (deviceInfo.isXboxPlatform()) {
						console.log('LoginUserSuccessAction XBOX store updated: ' + getState().login.logged);
					}
					if (deviceInfo.isPlayStationPlatform()) {
						console.log('LoginUserSuccessAction PlayStation store updated: ' + getState().login.logged);
					}
					antstreamService.socketConnect();
					dispatch(PostAuthenticationChecksAction(promoCode, isAutoLogin, isSignUp))
				} else {
					console.log('[ACCOUNT_DETAILS_ERROR_1]: ', resp.error);
					Elastic.logDirect(LOG_LEVEL.ERROR,"[ACCOUNT_DETAILS_ERROR_1] : " + resp.error);
					LocalStorage.clearAll('GetAccountDetailsAction');
					if (needXboxRetryMessage) {
						console.log('retrying...');
						messageNativeXboxRetryLogin();
					}
					return navigateToLocation(ROUTES.LOGIN);
				}
			}).catch((err) => {
				console.error('Account details failed: ' + err.message);
				LocalStorage.clearAll('GetAccountDetailsAction2');
				if (needXboxRetryMessage) {
					console.log('retrying...');
					messageNativeXboxRetryLogin();
				}
				navigateToLocation(ROUTES.LOGIN);
			});
	}
}

const PostAuthenticationChecksAction = (promoCode, isAutoLogin, isSignUp) => {
	return (dispatch, getState) => {
		const { isNotFullyRegistered } = getLoggedUser(getState());
		fetchLoginData(dispatch, promoCode, isSignUp);

		if(!isSignUp && isNotFullyRegistered) {
			return navigateToLocation(ROUTES.SIGNUP_COMPLETION);
		}

		const deepLink = LocalStorage.readDeepLinkStorage();
		if (deepLink) {
			console.log("Deep link is : " + deepLink);
			LocalStorage.removeDeepLinkStorage();
		}

		if(!isSignUp && deepLink) {
			tryDecodeDeepLinkAndNavigate(deepLink);
		} else {
			navigateToSavedLocation();
		}
	}
}

export const LOGIN_XBOX_ACTION = 'Login Xbox Action';
export const LoginXboxAction = (authCode) => {
	return (dispatch) => {
		dispatch({ type: LOGIN_XBOX_ACTION, authCode });

		RestAPI.getXboxCredentialsFromAntsparks(authCode).then(response => {
			const { accessToken, userId, player } = response;

			LocalStorage.writeRestUserData({
				email: player.userName,
				authToken: accessToken,
				userId: userId
			});

			dispatch(GetAccountDetailsAction());
		}).catch(error => {
			console.error('Failed to login via microsoft: ' + error);
			dispatch(LoginUserFailAction({ details: error.errors }));
			LocalStorage.clearAll('LoginXboxAction');
			navigateToLocation(ROUTES.LOGIN);
		});
	};
};

export const LOGIN_PLAYSTATION_ACTION = 'LOGIN_PLAYSTATION_ACTION';
export const LoginPlayStationAction = (authCode, redirectUri) => {
	return (dispatch) => {
		dispatch({ type: LOGIN_PLAYSTATION_ACTION, authCode });

		consoleLogWithTimestamp('/v2/auth/external/psn/login request started.');
		RestAPI.getPlayStationCredentialsFromAntsparks(authCode, redirectUri).then(response => {
			consoleLogWithTimestamp('/v2/auth/external/psn/login request finished.');
			const { accessToken, userId, expiresIn, displayName, player } = response;

			LocalStorage.writeRestUserData({
				expiresIn,
				displayName,
				email: player.userName,
				authToken: accessToken,
				userId: userId
			});
			consoleLogWithTimestamp('writeRestUserData finished.');

			consoleLogWithTimestamp('GetAccountDetailsAction started.');
			dispatch(GetAccountDetailsAction());
		}).catch(error => {
			console.error('Failed to login via playstation: ' + error);
			dispatch(LoginUserFailAction({ details: error.errors }));
			LocalStorage.clearAll('LoginPlayStationAction');
			navigateToLocation(ROUTES.LOGIN);
		});
	};
};

export const LOGIN_EPIC_GAMES_ACTION = 'Login Epic Games Action';
export const LoginEpicGamesAction = (authCode, accessToken = null, refreshToken = null, isAutoLogin = false) => {
	return (dispatch) => {
		dispatch({ type: LOGIN_EPIC_GAMES_ACTION, authCode, accessToken });
		RestAPI.getEpicGamesCredentials(authCode, accessToken, refreshToken)
			.then(response => {

				setTimeout(() => {
					const currentLocation = window.location.href;
					if (currentLocation.indexOf('/ingame-menu?') > -1) {
						return;
					}

					antstreamService
						.redeemEpicGamesEntitlements({ epicGamesInterval: true })
						.subscribe(result => dispatch(OnEpicGamesRedemptionResponse(result)));
				}, EpicGames.REDEEM_INTERVAL_MILISECONDS);

				// TODO: error is here
				dispatch(LoginUserAction(response));
				LocalStorage.writeEpicGamesAccessToken(response.accessToken);
				LocalStorage.writeEpicGamesRefreshToken(response.refreshToken);
			})
			.catch(error => {
				dispatch(LoginUserFailAction({ details: error.errors }));
				LocalStorage.clearAll('LoginEpicGamesAction');
				navigateToLocation(ROUTES.LOGIN);
			});
	};
};

export const LOGIN_FACEBOOK_ACTION = 'Login Facebook Action';
export const LoginFacebookAction = (accessToken, isAutoLogin = false) => {
    return dispatch => {
        dispatch({ type: LOGIN_FACEBOOK_ACTION, accessToken });

            const payload = {
                provider: 'FB',
                operation: 'login',
                data: {
                    fbAccessToken: accessToken
                }
            };
            antstreamAPIService.login.loginCreate(payload).then(async res => {
                const resp = res.data;
                if (!resp.error) {
                    LocalStorage.writeRestUserData({
                        // for new backend
                        email: resp.data.player.userName,
                        authToken: resp.data.accessToken,
                        userId: resp.data.userId
                    });

                    antstreamAPIService.accountDetails
                        .accountDetailsList({
                            headers: { Authorization: 'Bearer ' + resp.data.accessToken }
                        })
                        .then(accDetailRes => {
                            const accDetailResp = accDetailRes.data;
                            if (!accDetailResp.error) {
                                dispatch(LoginUserSuccessAction(accDetailResp.data.scriptData));
                                antstreamService.socketConnect();
                                dispatch(getFacebookUserDataAction(accessToken));

                                if (tryDecodeDeepLinkAndNavigate(LocalStorage.readDeepLinkStorage())) {
                                    //TODO: IMPLEMENT THIS FUNCTION
                                    LocalStorage.removeDeepLinkStorage();
                                } else {
                                    if (!isAutoLogin) {
                                        dispatch(SaveRoute(null));
                                        navigateToLocation(ROUTES.HOMEPAGE);
                                    } else {
                                        navigateToSavedLocation();
                                    }
                                }

                                fetchLoginData(dispatch, null, resp.newPlayer);
                            } else {
                                dispatch(LoginUserFailAction(resp.error));
                                LocalStorage.clearAll('LoginFacebookAction');
                            }
                        })
                        .catch(accountDetailsErr => {
                            dispatch(LoginUserFailAction(accountDetailsErr.message));
                            LocalStorage.clearAll('LoginFacebookAction2');
                        });
                } else {
                    dispatch(LoginUserFailAction(resp.error));
                    LocalStorage.clearAll('LoginFacebookAction3');
                }
            });

    };
};

export const REQUEST_WAYSUN_TOKEN_ACTION = 'REQUEST_WAYSUN_TOKEN_ACTION';
const REQUEST_WAYSUN_TOKEN_ACTION_FAIL = 'REQUEST_WAYSUN_TOKEN_ACTION_FAIL';

export const REQUEST_WAYSUN_TOKEN_ACTION_SUCCESS = 'REQUEST_WAYSUN_TOKEN_ACTION_SUCCESS';

export const RequestWaysunTokenAction = (userId, islogin) => {
	return (dispatch, getState) => {
		if(!userId) userId = getWaysunUserId(getState());
		dispatch({ type: REQUEST_WAYSUN_TOKEN_ACTION, userId });
		return fetch(`${window.config.REACT_APP_WAYSUN_URL}/oidc/antstream/login`, {
			method: 'post',
			body: JSON.stringify({
				user_id: userId
			}),
		  })
		  .then(response => response.json())
			.then(data => {
				if(data.status !== 'ok' || !data.details|| !data.details.order_token) {
					throw new Error()
				} else if (islogin) {
					dispatch(WaysunInitialisationAction({waysunAntstreamtoken:data.details.antstream_token}))
				} else {
					dispatch({ type: REQUEST_WAYSUN_TOKEN_ACTION_SUCCESS, waysunOrderToken: data.details.order_token })
				}
			})
			.catch((error) => {
				dispatch({ type: REQUEST_WAYSUN_TOKEN_ACTION_FAIL, error })
				addPopup(<GenericPopup
					okButtonLabel="Got it!"
					title="Something went wrong"
					message="Please try again later"
					onOkClicked={()=> GameWrapper.quitApp()}
				/>);
			});
	}
}

export const WAYSUN_INITIALISATION_ACTION = 'WAYSUN_INITIALISATION_ACTION';
const WAYSUN_INITIALISATION_ACTION_SUCCESS = 'WAYSUN_INITIALISATION_ACTION_SUCCESS';
const WAYSUN_INITIALISATION_ACTION_FAIL = 'WAYSUN_INITIALISATION_ACTION_FAIL';

export const WaysunInitialisationAction = ({waysunAntstreamtoken}) => {
	return (dispatch,getState) => {
		dispatch({ type: WAYSUN_INITIALISATION_ACTION, waysunAntstreamtoken });
		antstreamService
			.waysunInitialisation(waysunAntstreamtoken)
			.subscribe((resp) => {
				if (!resp.error || !resp.data) {
					const data = resp.data.message
					dispatch({ type: WAYSUN_INITIALISATION_ACTION_SUCCESS, data });
						const loginError = getLoginError(getState());
						if(loginError){
							console.log(resp)
							addPopup(<GenericPopup
								okButtonLabel="Got it!"
								title="Something went wrong"
								message="Please try again later"
								onOkClicked={()=> GameWrapper.quitApp()}
							/>);
						}else{
							dispatch(LoginUserAction({
								email: data.userName,
								password: data.password,
								isUserSubscribed: data.isUserSubscribed,
							}, true));
						}
				} else {
					console.log(resp)
					addPopup(<GenericPopup
						okButtonLabel="Got it!"
						title="Something went wrong"
						message="Please try again later"
						onOkClicked={()=> GameWrapper.quitApp()}
					/>);
					dispatch({ type: WAYSUN_INITIALISATION_ACTION_FAIL, resp });
				}
			});
	};
};

export const WAYSUN_SUCCESSFULL_IFRAME = 'WAYSUN_SUCCESSFULL_IFRAME';

export const WaysunSuccessfullIframe = (waysunOrdertoken) => {
	return (dispatch) => {
		dispatch({ type: WAYSUN_SUCCESSFULL_IFRAME, orderToken: waysunOrdertoken });
		antstreamService
			.waysunIframePayment(waysunOrdertoken)
		dispatch(WaysunUsedOrdertoken())
	};
};


export const WAYSUN_USED_ORDER_TOKEN = 'WAYSUN_USED_ORDER_TOKEN';
export const WaysunUsedOrdertoken = () => {
	return (dispatch) => {
		dispatch({ type: WAYSUN_USED_ORDER_TOKEN });
	};
};

export const LOGIN_USER_ACTION_SUCCESS = 'Login With Password Success';
const LoginUserSuccessAction = ({user,config,publisherLogos,avatars,challenge_message_icons,allTutorials,unlockedChallenges,unlockedChallengesType,favouritedGames,messageCount,connectionTesterPopupSeen,currentRegion,language}) => {
	return (dispatch, getState) => {
		// dispatch(FetchConnectionTesterPopupSuccess(connectionTesterPopupSeen));
		dispatch(setConfig(config));
		dispatch(FetchLogosSuccessAction(publisherLogos));
		dispatch(FetchBadgesSuccessAction(challenge_message_icons));
		dispatch(FetchAvatarsSuccessAction(avatars));
		dispatch(GetTutorialsCompletedActionSuccess(allTutorials));
		dispatch(FetchUnlockChallengesActionSuccess(unlockedChallenges,unlockedChallengesType));
		dispatch(FavouritedGamesSuccessAction(favouritedGames));
		dispatch(FetchMessagesAction());
		dispatch({ type: LOGIN_USER_ACTION_SUCCESS, payload:user });

		Elastic.logDeviceLink(user._id, deviceInfo.deviceID);
		GameWrapper.sendBase64AvatarToNative(getAvatarUrl(getState(),user.profile_image_id));
		AudioManager.playAudio(TYPE_LAUNCH);
		dispatch(GetPushServiceIdAction());
		if(currentRegion)dispatch(setCurrentRegion(currentRegion.ShortCode));
		if(language) {
			dispatch(UpdateUserLanguageAction(language));
		} else {
			dispatch(UpdateUserLanguageAction(getBrowserLanguage()));
		}
	};
};

export const LOGIN_USER_ACTION_FAIL = 'Login Fail';
export const LoginUserFailAction = (payload) => {
	return { type: LOGIN_USER_ACTION_FAIL, payload };
};

const getFacebookUserDataAction = (accessToken) => {
	return (dispatch, getState) => {
		getFacebookUserData(accessToken)
			.subscribe((resp) => {
				if (!resp.error) {
					dispatch(getFacebookUserDataActionSuccess(resp))
				}
				// const loggedUser = getState().app.loggedUser;
				// if(resp && resp.email && loggedUser && loggedUser.userName && loggedUser.userName.substr(0,3) === "FB_"){
					// dispatch(ChangeUserDetails(resp.email));
				// }
			});
	};
};

const getFacebookUserDataActionSuccess = ({id}) => {
	return(dispatch) => {
		if(id) {
			dispatch(SetUserAvatarAction(id));
		}
	};
};

export const CHANGE_USER_DETAILS = 'CHANGE_USER_DETAILS';
export const ChangeUserDetails = (userName) => {
	return (dispatch) => {
		dispatch({ type: CHANGE_USER_DETAILS });
		antstreamService
			.changeUserDetails(userName)
			.subscribe((resp) => {
				if (!resp.error) {
					dispatch(ChangeUserDetailsSuccess(userName));
				}
			});
	};
};

export const CHANGE_USER_DETAILS_SUCCESS = 'CHANGE_USER_DETAILS_SUCCESS';
export const ChangeUserDetailsSuccess = (userName) => {
	return (dispatch) => {
		dispatch({ type: CHANGE_USER_DETAILS_SUCCESS, userName});
	};
};

export const fetchLoginData = (dispatch, promoCode = null, isNewUser = false) => {
	let newPromoCode = promoCode;

	if (isNewUser !== null && isNewUser && (typeof promoCode === 'undefined' || promoCode === null)) {
		// Google Store users should get 12 months free promo
		if (((deviceInfo.platformType === PLATFORM_TYPE_ANDROID) || (deviceInfo.platformType === PLATFORM_TYPE_NVIDIA_SHIELD))
			&& !deviceInfo.isSetTopBoxPaymentFlow()) {
			newPromoCode = "ANDROIDFREE_0221_F12";
		}
		else if (deviceInfo.platformType === PLATFORM_TYPE_ANDROID_SAMSUNG) {
			newPromoCode = "SAMSUNGFREE_0221_F12";
		}
		else if (deviceInfo.storeType === STORE_TYPE_SAMSUNG_TV) {
			newPromoCode = "SAMSUNGFREE_0221_F12";
		}
		else if ((deviceInfo.platformType === PLATFORM_TYPE_FIRE_TV) || (deviceInfo.storeType === STORE_TYPE_AMAZON)) {
			newPromoCode = "AMAZONFREE_0221_F12";
		}
		// else if ((deviceInfo.platformType === PLATFORM_TYPE_IPHONE) || (deviceInfo.storeType === STORE_TYPE_APPLE_IOS)) {
		// 	newPromoCode = "FREE_TRIAL";
		// }
		else if(deviceInfo.platformType === PLATFORM_TYPE_MAC
			|| deviceInfo.platformType === PLATFORM_TYPE_WIN_UWP_X64
			|| deviceInfo.platformType === PLATFORM_TYPE_WIN32_X86
			|| deviceInfo.platformType === PLATFORM_TYPE_LINUX)
			{
				newPromoCode = "PLAYFREE";
			}
	}

	dispatch(FetchHomepageGamesAction());
	dispatch(FetchDebugSettingsAction());
	dispatch(fetchPostAuthenticationAction(newPromoCode, isNewUser));
};


export const FETCH_POST_AUTHENTICATION_ACTION = 'FETCH_POST_AUTHENTICATION_ACTION';
export const fetchPostAuthenticationAction = (promoCode, isNewUser) => {
	return (dispatch, getState) => {
		dispatch({ type: FETCH_POST_AUTHENTICATION_ACTION });

		const { authToken } = readRestUserData() || {};
		if (!authToken) handleNoAuthTokenIssue(FETCH_POST_AUTHENTICATION_ACTION);

			const body = {
				promoCode,
				isAntstreamApp: true,
				platformType: deviceInfo.platformType || PLATFORM_TYPE_WEB 
			};
			const params = {
				body: body,
				headers: { 'Authorization': "Bearer " + authToken }
			};
			antstreamAPIService.postLogin.postLoginCreate(params).then(({data}) => {
				if (!data) throw new Error('Something went wrong');
				if (data.error) throw new Error(data.error);
				if (!data.data) throw new Error('Something went wrong');

				const resp = data.data;

				// if promocode was used - take paymentData from promoCodeResponse
				if (resp.promoCodeResponse && resp.promoCodeResponse.paymentData) {
					resp.paymentData = resp.promoCodeResponse.paymentData;
				}

				dispatch(fetchPostAuthenticationActionSuccess(resp));

				const state = getState();

				if (deviceInfo.isPlayStationPlatform()) {
					handlePostLoginForPlaystation(resp);
				} else if (isXboxUser(state)) {
					displayPostLoginPopupForXboxUsers(resp);
				} else if (deviceInfo.storeType === STORE_TYPE_APPLE_IOS) {
					// displayIosWelcomeFreeTierPopup(isNewUser, resp, state, dispatch);
					displayIosNoSubscriptionOverlay(isNewUser, resp, state, dispatch);
				} else if (resp.freemiumV4User && resp.freemiumV4AccessAllowed !== true) {
					displayPostLoginPopupForFreemiumV2Users(state, dispatch);
				} else if (resp.freemiumV3User && resp.freemiumV3AccessAllowed !== true) {
					displayPostLoginPopupForFreemiumV3Users(state, dispatch);
				} else if (isFreeTierV2FeatureFlagEnabled(state) && !getUserIsSubscribed(state)) {
					displayPostLoginPopupForFreemiumV2Users(state, dispatch);
				}
			}).catch(catchErr => {
				const errMsg = 'Failed to fetch post login data: ' + catchErr.message;
				console.error(errMsg, catchErr);
				dispatch(fetchPostAuthenticationActionFailed());

				if (deviceInfo.isXboxPlatform() || deviceInfo.isPlayStationPlatform()) {
					const { authToken } = readRestUserData() || {};
					if (!authToken) handleNoAuthTokenIssue('post login catch');
					console.error('post login access token exists: ' + (authToken ? 1 : 0));
				}
			});

	};
};

function handlePostLoginForPlaystation(resp) {
	/**
	 * @type {{
	 *     subscription: boolean
	 *     paymentType: string
	 *     paymentDue: number
	 *     playStationStoreProduct?: {
	 *         freeClaim: string
	 *     }
	 * }}
	 **/
	const paymentData = resp.paymentData;
	if (paymentData?.subscription === true) return;

	const message = 'Antstream requires a subscription or Lifetime Edition to play. Redeem or purchase a subscription.';
	const buttonType = window.config.REACT_APP_ENV === 'dev' ? TYPE_HAS_TWO_BUTTONS : undefined;

	const productId = paymentData?.playStationStoreProduct?.freeClaim;

	addPopup(<GenericPopup
		genericClassName='checkout-offerings-popup'
		buttonType={buttonType}
		isPermanent
		isBackFunctionDisabled
		okButtonLabel="Subscribe"
		cancelButtonLabel="Cancel (DEV)"
		title="Subscription"
		message={message}
		onCancelClicked={() => closePopup()}
		onOkClicked={() => startNativePayment(null, productId)}
	>
		<div className='checkout-offerings-popup-img'/>
	</GenericPopup>);
}

/**
 * if xbox user and not subscribed - show expired subscription popup
 **/
function displayPostLoginPopupForXboxUsers(resp) {
	if (!resp || !resp.paymentData || !resp.paymentData.subscription) {
		addPopup(
			<XboxSubscriptionPopup
				type={xboxPopupType.SUBSCRIPTION_EXPIRED}
				isFromPostLogin={true} />
		);
	}
}

/**
 * if IOS user's first login and he is not subscribed - open the welcome free tier popup
 * if free trial subscription - show the popup
 **/
function displayIosWelcomeFreeTierPopup(isNewUser, resp, state, dispatch) {
	if (!isNewUser) return;

	// if not free trial subscription - do not show up the popup
	if (resp?.paymentData?.subscription && !isFreeTrialSubscription(resp.paymentData)) return;

	const delayedPage = isDelayedPage(state);

	const welcomeIosPopup = <IosWelcomePopup pageId='homepage_1' />;

	if (delayedPage) {
		// wait once we get to the home page and then show the pop-up
		dispatch(AddDelayedPopupAction(welcomeIosPopup));
	} else {
		// show the pop-up now
		setTimeout(() => addPopup(welcomeIosPopup), 2000);
	}
}

/**
 * if IOS user and not subscribed and sign up - open the payment overlay
 **/
function displayIosNoSubscriptionOverlay(isNewUser, resp, state, dispatch) {
	if (!isNewUser) return;
	if (resp?.paymentData?.subscription) return;

	const delayedPage = isDelayedPage(state);
	if (delayedPage) {
		dispatch(ToggleNativePaymentOnHomepage(true));
	} else {
		startNativePayment(APPLE_IOS_SKU);
	}
}

function displayPostLoginPopupForFreemiumV2Users(state, dispatch) {
	const delayedPage = isDelayedPage(state);

	const subscriptionPopup = <SubscriptionComparisonsPopup
		leftHeaderText={PREMIUM_POPUP_HEADER_TEXT_3}
		pageId='homepage_1'
	/>;

	if (delayedPage) {
		// wait once we get to the home page and then show the pop-up
		dispatch(AddDelayedPopupAction(subscriptionPopup));
	} else {
		// show the pop-up now
		setTimeout(() => addPopup(subscriptionPopup), 2000);
	}
}

/**
 * if this user is freemium v3 user and he is not subscribed
 * and he is using any platform except web or mobile
 * we must display non-closable popup
 **/
function displayPostLoginPopupForFreemiumV3Users(state, dispatch) {
	const delayedPage = isDelayedPage(state);

	const freemiumV3Text = ['Unleash a world of', 'classic gaming and', 'community fun!'];
	const subscriptionPopup = <SubscriptionComparisonsPopup
		customClassName='subscription-comparisons-popup--V3'
		leftHeaderText={freemiumV3Text}
		pageId='homepage_1'
		freemiumVersion='V3'
	/>;

	if (delayedPage) {
		// wait once we get to the home page and then show the pop-up
		dispatch(AddDelayedPopupAction(subscriptionPopup));
	} else {
		// show the pop-up now
		addPopup(subscriptionPopup);
	}
}

export const FETCH_POST_AUTHENTICATION_ACTION_SUCCESS = 'FETCH_POST_AUTHENTICATION_ACTION_SUCCESS';
export const fetchPostAuthenticationActionSuccess = (response) => {
	return (dispatch) => {
		dispatch({ type: FETCH_POST_AUTHENTICATION_ACTION_SUCCESS, response });
	};
};

export const FETCH_POST_AUTHENTICATION_ACTION_FAILED = 'FETCH_POST_AUTHENTICATION_ACTION_FAILED';
export const fetchPostAuthenticationActionFailed = () => {
	return (dispatch) => {
		dispatch({ type: FETCH_POST_AUTHENTICATION_ACTION_FAILED });
	};
};


export const LOGOUT_USER_ACTION = 'LogoutUserActionSuccess';
export const LogoutUserAction = (isNeedToSendRequest = true) => {
	clearHeaderData();
	return (dispatch, getState) => {
		if (isNeedToSendRequest) {
			sendLogOutRequest();
		}
		const stateBeforeLogout = getState();

		dispatch({ type: LOGOUT_USER_ACTION });
		dispatch({ type: APP_INIT_SUCCESS_ACTION });

		// since we reset all redux state on logout - we must keep ipStackObject
		if (stateBeforeLogout?.app?.ipStackObject) {
			dispatch(GetIpStackObjectSuccess(stateBeforeLogout.app.ipStackObject));
		}
	};
};

export const sendLogOutRequest = () => {
		const restData = readRestUserData();
		const authToken = restData?.authToken;
		if (!authToken) {
			console.error('Failed to send logout request - token not found');
			return;
		}

		antstreamAPIService.logout.logoutCreate({}, {
			headers: {'Authorization': "Bearer " + authToken}
		}).then(({data}) => {
			if (data && data.data && data.data.result) {
				antstreamService.socketDisconnect();
				KeepAlive.userLoggedOut();
				return;
			}

			throw new Error((data && data.error) || "Something went wrong on logout.");
		}).catch(logoutErr => {
			addPopup(<GenericPopup
				okButtonLabel="Got it!"
				title="Something went wrong"
			/>);
			console.error(logoutErr.message);
		});

};

export const SET_USER_AVATAR_ACTION = 'Set User Avatar Action';
export const SetUserAvatarAction = (avatarType) => {
	return (dispatch, getState) => {
		dispatch({ type: SET_USER_AVATAR_ACTION, payload:avatarType });

			const updAvaBody = {
				requestId: new Date().getTime() + "",
				data: {avatarUrl: avatarType}
			};
			const {authToken} = readRestUserData();
			antstreamAPIService.player.avatarUpdate(updAvaBody, {
				headers: {'Authorization': "Bearer " + authToken}
			}).then(({data}) => {
				if (data && data.result === "OK") {
					dispatch(ShowNotificationAction("Avatar Saved"));
				} else {
					dispatch(ShowNotificationAction("Failed to update avatar. Please try later."));
				}
			}).catch(avatarUpdErr => {
				dispatch(ShowNotificationAction("Failed to update avatar. Please try later."));
			});

		GameWrapper.sendBase64AvatarToNative(getAvatarUrl(getState(),avatarType));
	};
};

export const TOGGLE_NATIVE_PAYMENT_ON_HOMEPAGE = 'TOGGLE_NATIVE_PAYMENT_ON_HOMEPAGE';
export const ToggleNativePaymentOnHomepage = payload => dispatch => {
	dispatch({ type: TOGGLE_NATIVE_PAYMENT_ON_HOMEPAGE, payload });
};

export const SIGNUP_USER_ACTION = 'Signup User Action';
export const SignupUserAction = () => {
	return (dispatch, getState) => {

		const payload = getState().login.userFields;

		const origin = "direct";
		const attributionData = {
			"attribution_origin": deviceInfo.storeType,
			"attribution_id": deviceInfo.storeType,
			"campaign": "inapp"
		};

		const {email, password, displayName} = payload;
		dispatch({ type: SIGNUP_USER_ACTION, payload });

			const payloadBody = {
				provider: 'USER',
				operation: 'register',
				data: {
					userName: email,
					displayName: displayName,
					password: password,
					scriptData: {
						action: 'SimpleRegistration',
						origin,
						attributionData,
					}
				}
			};

			// if the user followed a sponsor tournament link - attach sponsor tournamentId to the request in order to skip email checking and avatar selecting
			const sponsorTournamentId = getState().tournaments?.externalSponsorTournamentId;
			if (sponsorTournamentId) {
				payloadBody.data.sponsor = { tournamentId: sponsorTournamentId };
			}

			antstreamAPIService.register.registerCreate(payloadBody).then(({data}) => {
				if (!data.error) {
					dispatch(SignupUserSuccessAction(payload));
					dispatch(LoginUserAction(payload, false, true));
				} else {
					dispatch(SignupFailAction(data));
					// navigateToLocation(ROUTES.SIGNUP);
				}
			}).catch(registerErr => {
				dispatch(SignupFailAction({error: registerErr.message}));
				// navigateToLocation(ROUTES.SIGNUP);
			});

	};
};


export const SIGNUP_USER_ACTION_SUCCESS = 'Signup User Success';
const SignupUserSuccessAction = (payload) => {
	return (dispatch) => {
		dispatch({ type: SIGNUP_USER_ACTION_SUCCESS, payload });
	};
};

export const SIGNUP_USER_ACTION_FAIL = 'Signup User Fail';
const SignupFailAction = (payload) => {
	return (dispatch) => {
		dispatch({ type: SIGNUP_USER_ACTION_FAIL, payload });
	};
};

export const USER_FULLY_REGISTERED_REQUEST = 'USER FULLY REGISTERED REQUEST';
export const UserFullyRegisteredRequestAction = (avatarType, displayName) => {
	return (dispatch) => {
		const payload = { avatarType, displayName}
		dispatch({ type: USER_FULLY_REGISTERED_REQUEST, payload});

			const {authToken} = readRestUserData();
			const finishRegBody = {
				requestId: new Date().getTime() + "",
				data: {
					action: 'FinishRegistration',
					avatarUrl: avatarType,
					displayName: displayName,
				}
			};
			const finishRegOpts = {headers: {'Authorization': "Bearer " + authToken}};
			antstreamAPIService.player.avatarUpdate(finishRegBody, finishRegOpts).then(({data}) => {
				if (data.result === "OK") {
					dispatch(UserFullyRegisteredSuccessAction(payload));
				} else {
					dispatch(UserFullyRegisteredFailAction(data));
					dispatch(ShowNotificationAction("Failed to update name and avatar. Please try again."));
				}
			}).catch(finishRegErr => {
				dispatch(UserFullyRegisteredFailAction(finishRegErr));
				dispatch(ShowNotificationAction("Failed to update name and avatar. Please try again."));
			});

	};
};


export const USER_FULLY_REGISTERED_SUCCESS = 'USER FULLY REGISTERED SUCCESS';
const UserFullyRegisteredSuccessAction = (payload) => {
	return (dispatch) => {
		dispatch({ type: USER_FULLY_REGISTERED_SUCCESS, payload });
	};
};

export const USER_FULLY_REGISTERED_FAIL = 'USER FULLY REGISTERED FAILED';
const UserFullyRegisteredFailAction = (payload) => {
	return (dispatch) => {
		dispatch({ type: USER_FULLY_REGISTERED_FAIL, payload });
	};
};

export const SET_SIGNUP_FIELD_ACTION = 'Set Signup Field Action';
export const SetSignupFieldAction = (payload) => {
	return { type: SET_SIGNUP_FIELD_ACTION, payload };
};

export const CHECK_EMAIL_ACTION = 'Check Email Action';
export const CheckEmailAction = (email) => {
	return (dispatch) => {
		dispatch({ type: CHECK_EMAIL_ACTION });

			const payload = {
				operation: "register",
				provider: "USER",
				data: {
					action: "CheckEmail",
					email: email,
				},
			};
			antstreamAPIService.register.registerCreate(payload).then(({data}) => {
				if (data.error) {
					dispatch(CheckEmailFailAction({error: data.error}));
				} else {
					dispatch(CheckEmailSuccessAction(data));
				}
			}).catch(registerErr => {
				dispatch(CheckEmailFailAction({error: registerErr.message}));
			});

	};
};

export const CHECK_EMAIL_ACTION_SUCCESS = 'Check Email Success';
const CheckEmailSuccessAction = (payload) => {
	return { type: CHECK_EMAIL_ACTION_SUCCESS, payload };
};

export const CHECK_EMAIL_ACTION_FAIL = 'Check Email Fail';
const CheckEmailFailAction = (payload) => {
	return { type: CHECK_EMAIL_ACTION_FAIL, payload };
};

export const CHECK_EMAIL_ACTION_CLEAR = 'Check Email Clear';
export const CheckEmailClearAction = () => {
	return { type: CHECK_EMAIL_ACTION_CLEAR };
};

export const CHECK_DISPLAY_NAME_ACTION = 'CHECK_DISPLAY_NAME_ACTION';
export const CheckDisplayNameAction = (displayName, onResponse) => {
	return (dispatch) => {
		dispatch({ type: CHECK_DISPLAY_NAME_ACTION });

			const payload = {
				operation: "register",
				provider: "USER",
				data: {
					action: "CheckUserName",
					displayName,
				},
			};
			antstreamAPIService.register.registerCreate(payload).then(({data}) => {
				if (data.error) {
					dispatch(checkDisplayNameFailAction({error: data.error}));
					if (onResponse) onResponse(false);
				} else {
					dispatch(checkDisplayNameSuccessAction({displayName: data.message}));
					if (onResponse) onResponse(true);
				}
			}).catch(checkDisplayNameErr => {
				dispatch(checkDisplayNameFailAction({error: checkDisplayNameErr.message}));
				if (onResponse) onResponse(false);
			});

	};
};

export const CHECK_GAMER_TAG_ACTION_SUCCESS = 'CHECK_GAMER_TAG_ACTION_SUCCESS';
const checkDisplayNameSuccessAction = (payload) => {
	return { type: CHECK_GAMER_TAG_ACTION_SUCCESS, payload };
};

export const CHECK_GAMER_TAG_ACTION_FAIL = 'CHECK_GAMER_TAG_ACTION_FAIL';
const checkDisplayNameFailAction = (payload) => {
	return { type: CHECK_GAMER_TAG_ACTION_FAIL, payload };
};

export const CHECK_GAMER_TAG_ACTION_CLEAR = 'CHECK_GAMER_TAG_ACTION_CLEAR';
export const checkDisplayNameActionClear = () => {
	return { type: CHECK_GAMER_TAG_ACTION_CLEAR };
};

// Password Reset
export const REQUEST_PASSWORD_RESET_ACTION = 'Request Password Reset Action';
export const RequestPasswordResetAction = ( email ) => {
	return (dispatch) => {
		dispatch({ type: REQUEST_PASSWORD_RESET_ACTION });

			antstreamAPIService.passwordRequest.passwordRequestCreate({
				body: {data: {email: email}}
			}).then(({data}) => {
				if (data && data.data && data.data.passwordRecoveryTokenSent) {
					dispatch(RequestPasswordResetActionSuccess());
					navigateToLocation(ROUTES.RESET_PASSWORD);
				} else {
					dispatch(RequestPasswordResetActionFail({error: (data && data.error) || 'Something went wrong'}));
				}
			}).catch(passwordRequestErr => {
				dispatch(RequestPasswordResetActionFail({error: passwordRequestErr.message}));
			});

	};
};

export const REQUEST_PASSWORD_RESET_ACTION_SUCCESS = 'Request Password Reset Success';
const RequestPasswordResetActionSuccess = () => {
	return (dispatch) => {
		dispatch({type: REQUEST_PASSWORD_RESET_ACTION_SUCCESS});
	};
};


export const REQUEST_PASSWORD_RESET_ACTION_FAIL = 'Request Password Reset Fail';
const RequestPasswordResetActionFail = (payload) => {
	return(dispatch) => {
		dispatch({type: REQUEST_PASSWORD_RESET_ACTION_FAIL, payload});
		addPopup(<GenericPopup
			okButtonLabel="Got it!"
			title="Something went wrong"
			message="That account does not exist. Please check your email and try again."
		/>, {}, true);
	};
};

export const RESET_PASSWORD_ACTION = 'Reset Password Action';
export const ResetPasswordAction = ( payload ) => {
	return (dispatch) => {
		dispatch({ type: RESET_PASSWORD_ACTION });
		const {token, email, password} = payload;

			antstreamAPIService.resetPassword.resetPasswordCreate({
				data: {email, token, newPassword: password}
			}).then(({data}) => {
				if (data && data.data && data.data.passwordReset) {
					dispatch(ResetPasswordActionSuccess());
					navigateToSavedLocation();
				} else {
					dispatch(ResetPasswordActionFail({error: (data && data.error) || 'Something went wrong'}));
					addPopup(<GenericPopup
						okButtonLabel="Got it!"
						title="Error"
						message={data.error || "Something went wrong."}
					/>, {}, true);
				}
			}).catch(passwordResetErr => {
				dispatch(ResetPasswordActionFail({error: passwordResetErr.message}));
				addPopup(<GenericPopup
					okButtonLabel="Got it!"
					title="Error"
					message={passwordResetErr.message || "Something went wrong."}
				/>, {}, true);
			});

	};
};

export const RESET_PASSWORD_ACTION_FAIL = 'Reset Password Fail';
const ResetPasswordActionFail = (payload) => {
	return(dispatch) => {
		dispatch({type: RESET_PASSWORD_ACTION_FAIL, payload});
	};
};

export const RESET_PASSWORD_ACTION_SUCCESS = 'Reset Password Success';
const ResetPasswordActionSuccess = () => {
	return(dispatch) => {
		dispatch({type: RESET_PASSWORD_ACTION_SUCCESS});
	};
};

export const LAST_TIME_ACC_ALREADY_SUBSCRIBED_ACTION = 'LAST_TIME_ACC_ALREADY_SUBSCRIBED_ACTION';
const SetLastTimeAccountAlreadySubscribedShown = (payload) => {
	return(dispatch) => {
		dispatch({type: LAST_TIME_ACC_ALREADY_SUBSCRIBED_ACTION, payload});
	};
};

export const OnNativePaymentResponse = (data, onSuccessCallback, onErrorCallback) => {
	return (dispatch, getState) => {
		const { authToken } = readRestUserData() || {};
		if (!authToken) handleNoAuthTokenIssue('OnNativePaymentResponse');

		const params = {
			body: data,
			headers: { 'Authorization': "Bearer " + authToken }
		};

		antstreamAPIService.payment.nativeResponseCreate(params).then(({data}) => {
			const errorMessage = data?.error || data?.data?.error || data?.data?.data?.error;
			if (deviceInfo.storeType === STORE_TYPE_APPLE_IOS && errorMessage) {
				
				/** do not show the same popup multiple times (10 seconds) **/
				const state = getState();
				const now = new Date().getTime();
				if (state?.login?.lastTimeAccountAlreadySubscribedShown) {
					const lastTimeShownLessThan10SecAgo = state?.login?.lastTimeAccountAlreadySubscribedShown > (now - (10 * 1000)); 
					if (lastTimeShownLessThan10SecAgo) {
						return;
					}
				} else {
					dispatch(SetLastTimeAccountAlreadySubscribedShown(now));
				}

				const delayedPage = isDelayedPage(getState());
				const iosAccountSubscribedPopup = <GenericPopup
					okButtonLabel="Got it!"
					title="There is already an account subscribed on this device"
					message={errorMessage}
				/>;
				if (delayedPage) {
					// wait once we get to the home page and then show the pop-up
					dispatch(AddDelayedPopupAction(iosAccountSubscribedPopup));
				} else {
					addPopup(iosAccountSubscribedPopup, {}, true);
				}
				throw new Error(errorMessage);
			}

			if (!data || !data.data) throw new Error(data?.error || 'Something went wrong');
			if (data.error) throw new Error(data.error);
			
			const resp = data.data.data;

			/**If PS subscription was successful - close the post-login ("Claim you subscription" or "Checkout our offerings") popup**/
			if (deviceInfo.isPlayStationPlatform()) {
				if (resp?.paymentData?.subscription === true) {
					closePopup();
				}
			}

			// Check if we're actually subscribed based on the response
			// from the Gamesparks native callbacks
			// First, assume it's a Google & Amazon subscription reply
			let isSubscribed = (resp && !!resp.subscription);
			if (deviceInfo.storeType === STORE_TYPE_MICROSOFT){
				GameWrapper.getMicrosoftStoreId(resp.token);
				if(resp.token) {
					isSubscribed = true;		// Microsoft subscribed
				}
			} else if (deviceInfo.storeType === STORE_TYPE_EPIC) {
				dispatch(OnEpicGamesRedemptionResponse(resp));
			} else {
				dispatch(FetchPaymentDataActionSuccess(resp));
			}

			if (onSuccessCallback) onSuccessCallback(resp);
		}).catch(catchErr => {
			console.error('Failed to process native payment response: ' + catchErr.message);
			if (onErrorCallback) onErrorCallback(catchErr.message);
		});

	};
};

export const ON_EPIC_GAMES_REDEMPTION_RESPONSE = 'On Epic Games Redemption Response';
export const OnEpicGamesRedemptionResponse = response => {
	return (dispatch) => {
		if (response.error) {
			GameWrapper.clearLoginCredentials();
			LocalStorage.clearAll('OnEpicGamesRedemptionResponse');
			dispatch(LogoutUserAction());

			if (deviceInfo.storeType === STORE_TYPE_EPIC) {
				addPopup(<GenericPopup
					okButtonLabel="Quit"
					message="Your Epic Games account session has expired. Please launch Antstream again."
					onOkClicked={GameWrapper.quitApp}
				/>, {}, true);
			} else {
				addPopup(<GenericPopup
					okButtonLabel="Got it!"
					message="Your Epic Games account session has expired. Please login again."
				/>, {}, true);
				navigateToLocation(ROUTES.LOGIN);
			}
			return;
		}

		if (response.accessToken) {
			LocalStorage.writeEpicGamesAccessToken(response.accessToken);
		}

		if (response.refreshToken) {
			GameWrapper.getPlatformLoginInfo({ refreshToken: response.refreshToken });
			LocalStorage.writeEpicGamesRefreshToken(response.refreshToken);
		}

		if (response.purchase && response.purchase.result === 'subscribed') {
			addPopup(<ShopResultPopup
				title="Purchase successful!"
				sku={response.purchase.skuid}
			/>);
		} else if (response.purchase && response.purchase.result && response.purchase.result !== 'abandoned') {
			addPopup(<ShopResultPopup
				className="shop-error"
				title="Something went wrong!"
				contentText="Please try again later."
			/>);
		}

		if (!response.purchase && response.entitlements && response.entitlements.length > 0) {
			response.entitlements.forEach(entitlement => {
				const sku = EpicGames.getSkuByCatalogueId(entitlement.entitlementName);
				if (!sku) return;

				addPopup(<ShopResultPopup
					title="Purchase successful!"
					sku={sku}
				/>);
			})
		}
	}
}


const FETCH_PAYMENT_DATA_ACTION = 'FETCH_PAYMENT_DATA_ACTION';
export const FetchPaymentDataAction = () => {
	return (dispatch) => {
		dispatch({ type: FETCH_PAYMENT_DATA_ACTION });

		const { authToken } = readRestUserData() || {};
		if (!authToken) handleNoAuthTokenIssue('FETCH_PAYMENT_DATA_ACTION');

			const params = { headers: { 'Authorization': "Bearer " + authToken } };

			antstreamAPIService.payment.dataList(params).then(({data}) => {
				if (!data || !data.data) throw new Error(data?.error || 'Something went wrong');
				if (data.error) throw new Error(data.error);

				const resp = data.data;
				dispatch(FetchPaymentDataActionSuccess(resp));
			}).catch(catchErr => {
				console.error('Failed to fetch payment data: ' + catchErr.message);
				dispatch(FetchPaymentDataActionSuccess(null));
			});

	};
};

export const FETCH_PAYMENT_DATA_ACTION_SUCCESS = 'FETCH_PAYMENT_DATA_ACTION_SUCCESS';
export const FetchPaymentDataActionSuccess = (payload) => {
	return (dispatch)=>{
		dispatch({type: FETCH_PAYMENT_DATA_ACTION_SUCCESS, payload });
	};
};


const FETCH_PAYMENT_PLANS_ACTION = 'FETCH_PAYMENT_PLANS_ACTION';
export const FetchPaymentPlans = () => {
	return (dispatch) => {
		dispatch({ type: FETCH_PAYMENT_PLANS_ACTION });

		if(isDeviceWithNativePayments()
		) {
			GameWrapper.getStoreCatalogue();
		} else {
			dispatch(FetchStripePlans());
		}
	};
};


const FETCH_SPRITE_PLANS_ACTION = 'FETCH_SPRITE_PLANS_ACTION';
export const FetchStripePlans = () => {
	return (dispatch) => {
		dispatch({ type: FETCH_SPRITE_PLANS_ACTION });

		const { authToken } = readRestUserData() || {};
		if (!authToken) handleNoAuthTokenIssue('FETCH_SPRITE_PLANS_ACTION');
			const params = { headers: { 'Authorization': "Bearer " + authToken } };
			const query = { action: 'getStripePlans' };

			antstreamAPIService.payment.plansList(query, params).then(({data}) => {
				if (!data || !data.data) throw new Error(data?.error || 'Something went wrong');
				if (data.error) throw new Error(data.error);

				const resp = data.data.stripePlans;
				dispatch(FetchPaymentPlansSuccess(resp));
			}).catch(catchErr => {
				console.error('Failed to fetch payment plans: ' + catchErr.message);
				dispatch(FetchPaymentPlansSuccess(null));
			});

	};
};

const FETCH_TRUE_PLANS_ACTION = 'FETCH_TRUE_PLANS_ACTION';
export const FetchTruePaymentPlans = () => {
	return (dispatch) => {
		dispatch({ type: FETCH_TRUE_PLANS_ACTION });
		antstreamService
			.getTruePlans()
			.subscribe(
				(resp) => {
					const filteredPlans = resp && resp.filter(({ currency, interval}) => currency === 'thb' && interval === 'month')
					dispatch(FetchPaymentPlansSuccess(filteredPlans));
				},
				() => {
					dispatch(FetchPaymentPlansSuccess(null));
				}
			);
	};
};

export const FETCH_PAYMENT_PLANS_SUCCESS = 'FETCH_PAYMENT_PLANS_SUCCESS';
export const FetchPaymentPlansSuccess = (payload) => {
	return (dispatch)=>{
		dispatch({type: FETCH_PAYMENT_PLANS_SUCCESS, payload });
	};
};

const FETCH_TRUE_PAYMENT_SOURCES_ACTION = 'FETCH_TRUE_PAYMENT_SOURCES_ACTION';
export const FetchTruePaymentSources = () => {
	return (dispatch) => {
		dispatch({ type: FETCH_TRUE_PAYMENT_SOURCES_ACTION });
		antstreamService
			.getTruePaymentSources()
			.subscribe(
				(resp) => {
					const filteredSources = resp && resp.filter(({ type }) => type === 'dcb')
					dispatch(FetchPaymentSourcesSuccess(filteredSources));
				},
				() => {
					dispatch(FetchPaymentSourcesSuccess(null));
				}
			);
	};
};

export const FETCH_PAYMENT_SOURCES_SUCCESS = 'FETCH_PAYMENT_SOURCES_SUCCESS';
export const FetchPaymentSourcesSuccess = (payload) => {
	return (dispatch)=>{
		dispatch({type: FETCH_PAYMENT_SOURCES_SUCCESS, payload });
	};
};

export const SET_MICROSOFT_STORE_ID = 'SET_MICROSOFT_STORE_ID';
export const setMicrosoftStoreId = (payload) => {
	return (dispatch)=>{
		dispatch({type: SET_MICROSOFT_STORE_ID});
		antstreamService
			.setMicrosoftStoreId(payload)
			.subscribe(
				(resp) => dispatch(FetchPaymentDataActionSuccess(resp)),
				() => dispatch(FetchPaymentDataActionSuccess(null))
			);
	};
};

export const SEND_SELECTED_SKU_ACTION = 'SEND_SELECTED_SKU_ACTION';
export const SendSelectedSKU = (sku , id) => {
	return (dispatch) => {
		dispatch({type: SEND_SELECTED_SKU_ACTION});
		GameWrapper.startNativePayment(sku, id);
	};
};

export const FETCH_MICROSOFT_PRODUCTS_ACTION = 'FETCH_MICROSOFT_PRODUCTS_ACTION';
export const FetchMicrosoftProductsAction = () => {
	return (dispatch) => {
		dispatch({ type: FETCH_MICROSOFT_PRODUCTS_ACTION });
		messageNativeMicrosoftProductListings();

		// const { authToken } = readRestUserData();
		// const params = { headers: { 'Authorization': "Bearer " + authToken } };
		//
		// antstreamAPIService.v2.paymentsMicrosoftProductsList(params).then(({data}) => {
		// 	if (!data || !data.data) throw new Error(data?.error || 'Something went wrong');
		// 	if (data.error) throw new Error(data.error);
		//
		// 	/**
		// 	 * @type {Array<{
		// 	 *     productId: string
		// 	 *     price: number
		// 	 *     currency: string
		// 	 *     title: string
		// 	 * }>}
		// 	 * */
		// 	const resp = data.data;
		// 	dispatch(FetchMicrosoftProductsActionSuccess(resp));
		// }).catch(catchErr => {
		// 	console.error('Failed to fetch microsoft subscription products: ' + catchErr.message);
		// 	dispatch(FetchMicrosoftProductsActionError(catchErr.message));
		// });
	};
};

export const FETCH_MICROSOFT_PRODUCTS_ACTION_SUCCESS = 'FETCH_MICROSOFT_PRODUCTS_ACTION_SUCCESS';
export const FetchMicrosoftProductsActionSuccess = (payload) => {
	return (dispatch)=>{
		dispatch({type: FETCH_MICROSOFT_PRODUCTS_ACTION_SUCCESS, payload });
	};
};

export const FETCH_MICROSOFT_PRODUCTS_ACTION_ERROR = 'FETCH_MICROSOFT_PRODUCTS_ACTION_ERROR';
export const FetchMicrosoftProductsActionError = (payload) => {
	return (dispatch)=>{
		dispatch({type: FETCH_MICROSOFT_PRODUCTS_ACTION_ERROR, payload });
	};
};

export const XBOX_SUBSCRIPTION_ACTION_PENDING = 'XBOX_SUBSCRIPTION_ACTION_PENDING';
export const XboxSubscriptionActionPending = () => {
	return (dispatch) => dispatch({ type: XBOX_SUBSCRIPTION_ACTION_PENDING });
};

export const XBOX_SUBSCRIPTION_ACTION_FINISHED = 'XBOX_SUBSCRIPTION_ACTION_FINISHED';
export const XboxSubscriptionActionFinished = () => {
	return (dispatch) => dispatch({ type: XBOX_SUBSCRIPTION_ACTION_FINISHED });
};

export const handleLogoutFlow = (dispatch, isNeedToSendRequest) => {
	clearLoginCredentials();

	dispatch(LogoutUserAction(isNeedToSendRequest));
	clearAll('logout2'); // clear local storage data after logout action fired, because we need authToken for this

	if (deviceInfo.isTrueTvDevice()) {
		navigateToLocation(ROUTES.TRUE_AUTH, { logoutAction: true });
	} else {
		navigateToLocation(ROUTES.LOGIN);
	}
};
