import amiga from './amiga.png';
import amigacd32 from './amigacd32.png';
import amstrad from './amstrad.png';
import amstradcpc from './amstradcpc.png';
import atari8bit from './atari8bit.png';
import atari2600 from './atari2600.png';
import atari7800 from './atari7800.png';
import bbcmicro from './bbcmicro.png';
import arcade from './arcade.png';
import c64 from './c64.png';
import challenges from './challenges.png';
import megadrive from './megadrive.png';
import msx from './msx.png';
import nes from './nes.png';
import neogeo from './neogeo.png';
import snes from './snes.png';
import spectrum from './spectrum.png';
import atarilynx from './lynx.png';
import atari5200 from './atari5200.png';
import jaguar from './jaguar.png';
import gameboy from './gameboy.png';
import gameboycolor from './gameboycolor.png';
import dos from './dos.png';
import sonyplaystation from './sonyplaystation.png';
import console8 from './console8.png'

export default {
    amiga,
    amigacd32,
    amstrad,
    amstradcpc,
    atari8bit,
    atari2600,
    atari7800,
    bbcmicro,
    arcade,
    c64,
    challenges,
    megadrive,
    msx,
    neogeo,
    nes,
    snes,
    spectrum,
    atarilynx,
    atari5200,
    jaguar,
    gameboy,
    gameboycolor,
    dos,
    sonyplaystation,
    console8
};
