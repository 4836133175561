import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getIsAppInitialised, getRoutingSearch } from '../../app.selectors';
import { AppInitAction, GetIpStackObjectSuccess } from '../../app.actions';
import { navigateToSavedLocation } from '../../app.router';
import { getDeviceInfo, openUrlOnDefaultBrowser, quitApp } from '../../assets/lib/game-wrapper';
import logo from '../../assets/images/splash/Antstream_Logo_White_Long.svg';
import './splash.component.less';
import { getUsersLocationData } from "../../assets/lib/utils";
import { addPopup } from '../popup/popup.component';
import GenericPopup from '../popup/generic-popup/generic-popup.component';
import DeviceInfo, {STORE_TYPE_SAMSUNG_TV} from '../../assets/lib/deviceInfo';
import { readUserData } from '../../assets/lib/local-storage';
import AudioManager from '../../app.audio';
import serverIcon from '../../assets/images/global/server-icon.svg';
import NewContentPopup from '../popup/new-content-popup/new-content-popup.component';
import { SetControlPreferencesTransparencyAction, SetControlPreferencesVolumeAction } from '../settings/settings.actions';
import { ExternalSponsorTournamentLinkUsed } from '../tournaments/tournaments.actions';

const getUserLocation = async () => {
	try {
		const userLocation = await getUsersLocationData();

		if (!userLocation.error) {
			return userLocation;
		} else {
			console.log("userLocationError", userLocation.error);
			return null;
		}
	} catch (error) {
		console.log("userLocationError", error);
		return null;
	}
}

const getBlacklistData = async () => {
	try {
		let response = await fetch('https://cdn.antstream.com/app-files/game-config/blacklist.json');
		let responseJson = await response.json();
		return responseJson;
	} catch (error) {
		console.error(error);
		return null;
	}
}

const checkIfRegionIsSupported = async (userLocation) => {
	if (userLocation && userLocation.country_code) {
		const blackListData = await getBlacklistData();
		if (blackListData && blackListData.filter(function (e) {
			return e["Country Code"] === userLocation.country_code;
		}).length > 0) {
			return false;
		}
	}
		return true;
}

class SplashComponent extends Component {
	UNSAFE_componentWillMount() {
		this.props.dispatch(AppInitAction());
		getDeviceInfo();

		const { controlPreferences = {} } = readUserData() || {};
		if (controlPreferences.volume !== undefined) {
			AudioManager.setMasterVolume(controlPreferences.volume);
			this.props.dispatch(SetControlPreferencesVolumeAction(controlPreferences.volume));
		}
		if (controlPreferences.transparency !== undefined) {
			this.props.dispatch(SetControlPreferencesTransparencyAction(controlPreferences.transparency));
		}
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		const { queryParameters } = this.props;

		if (!this.props.appIsInitialized && newProps.appIsInitialized) {
			setTimeout(async () => {
				const userLocation = await getUserLocation();
				this.props.dispatch(GetIpStackObjectSuccess(userLocation));
				const isRegionSupported = await checkIfRegionIsSupported(userLocation);

				if (queryParameters && queryParameters.sponsor && queryParameters.sponsor === 'true') {
					this.props.dispatch(ExternalSponsorTournamentLinkUsed(queryParameters.id));
				}

				if (!isRegionSupported) {
					addPopup(
						<NewContentPopup
							imgSrc={serverIcon}
							title="Antstream is not supported in your region"
							message="As a streaming service, Antstream works best when you are close to available servers."
							buttonText="OK"
							onOkClicked={() => { navigateToSavedLocation(); }} 
						/>
					);
				}

				if (DeviceInfo.isTrueTvDevice() && DeviceInfo.isOldVersion("2.1.1290", DeviceInfo.version)) { // TODO this could be a race condition if it deviceType returns after but is very unlikely to happen
					addPopup(<GenericPopup
						okButtonLabel="Update Now"
						title="Update Required"
						message="An application update is required before you can continue."
						isPermanent
						onOkClicked={() => {
							openUrlOnDefaultBrowser("https://play.google.com/store/apps/details?id=com.antstream.player");
						}}
					/>);
				}
				else {
					navigateToSavedLocation();
				}
			}, 1000);
		}
	}

	renderLogo() {
		return <img src={logo} className={`logo`} alt="logo" />;
	}

	render() {
		return (
			<section className="splash-component">
				{this.renderLogo()}
			</section>
		);
	}
}

const mapStateToProps = state => {
	return {
		queryParameters: getRoutingSearch(state),
		appIsInitialized: getIsAppInitialised(state),
	};
};

export const Splash = connect(mapStateToProps)(SplashComponent);
