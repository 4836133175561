import React, { Component } from 'react';
import { connect } from 'react-redux';
import { store } from '../../configureStore';
import './webrtc-game.component.less';

import {
  FetchCurrentPosition,
  PauseGameAction,
  SetGameFinished,
  SetGameQuit,
  UnpauseGameAction,
} from '../game-details/game-details.actions';

import { PacketTypeString, InputSetupRawFlags } from "../../assets/lib/AntRTCNetworkConstants";
import AntRTC from '../../assets/lib/AntRTC';
import AntRTC_Demo from '../../assets/lib/AntRTC_Demo';
import LegacyAntRTC from '../../assets/lib/AntRTC_Legacy';
import { getTournamentShortCode } from '../../assets/lib/utils';
import InGameComponent from '../ingame-menu/in-game-menu.component';
import inputManager from '../../assets/lib/inputmanager';

import {
  getCurrentGameDetailsId,
  getGameState,
  getLastSessionObject,
} from '../game-details/game-details.selector';

import { FetchGameDetailed } from '../../entities/games/games.actions';

import { GAME_STATE, CG_DATA_DISPLAY_TYPES } from '../../constants';
import { addPopup } from '../popup/popup.component';
import GenericPopup from '../popup/generic-popup/generic-popup.component';
import { navigateToLocation, navigateBack, ROUTES } from '../../app.router';
import KeepAlive from '../../assets/lib/KeepAlive';
import { Loader } from '../common/loader/loader.component';

import {
  getAssetsUrlWithFolder,
  getLoggedUser,
  getRunningGameChallengeStyle,
} from '../../app.selectors';

import InGameHeader from '../ingame-header/in-game-header.component';
import ChallengeAnimation from '../animations/challenge-animation/challenge-animation.component';

import {
  getCurrentTournamentData,
  getLeaderBoardObject
} from '../tournaments/tournaments.selector.js';

import {
  FetchTournamentsDetailAction
} from '../tournaments/tournaments.actions';

import { map, throttle } from 'lodash';
import InGameOSK from '../ingame-osk/ingame-osk.component';
import { getSelectedGiantSlayerChallenge } from '../game-details/game-details.selector';
import { getGiants } from '../challenge/challenge.selectors';
import { getChallengeById } from '../../entities/entities.selectors';
import { getControlPreferencesTransparency, getControlPreferencesVolume } from '../settings/settings.selector';
import Elastic, {LOG_LEVEL} from '../../assets/lib/elastic.lib';
import * as LocalStorage from '../../assets/lib/local-storage';

import blackVideo from '../../assets/video/black.mp4';
import { FOLDER_TYPES } from '../../assets/lib/FolderTypes';
import deviceInfo,{ STORE_TYPE_SAMSUNG_TV } from '../../assets/lib/deviceInfo';
import { paramsToObject } from '../../app.helpers';

class _WebRTCGameComponent extends Component {

  constructor(props) {
    super(props);

    this.postprocessing = false;
    this.connectLocalHost = false;

    // if ('requestVideoFrameCallback' in HTMLVideoElement.prototype) {
    //   // (imayo) Legacy WebRTC depends on requestVideoFrameCallback to enable Post Processing
    //   this.postprocessing = true;
    // }

    this.videoPostprocessingCanvas = React.createRef();
    this.superSamplingCanvas = React.createRef();
    this.controlsCanvas = React.createRef();
    this.perfCanvas = React.createRef();
    this.enablePerfCanvas = this.shouldDisplayStreamingStatistics();

    const serverIPDash = this.props.serverIP?.replace(/\./g, '-');
    this.webRTCServer = `${serverIPDash}.xip.antstream.com`;
    this.sessionId = this.props.sessionId;
    this.serverIP = this.props.serverIP;
    this.gameId = this.props.gameId;
    if(this.props.webSocketServerPort === undefined && this.props.serverPort !== undefined) {
        this.webSocketServerPort = this.props.serverPort - 27050 + 4001;
    }
    else {
        this.webSocketServerPort = this.props.webSocketServerPort;
    }
    this.webSocketProtocol = 'wss://';
    this.webrtc = null;
    this.timeoutCallNotStartedId = null;

    const userData = LocalStorage.readUserData();
    this.fixedJoysticks = userData && userData.controlPreferences && userData.controlPreferences.dpadPreference === 'fixed';

    this.samsungMessagePortListenerId = null;
    this.samsungLocalMsgPort = null;
    this.showHeaderTimeout = null;
    this.currentPositionTimeout = null;
    this.state = {
      isPopupActive: false,
      isLoading: true,
      scores: [],
      showHeader: true,
      currentRank: -1,
      challengeAnim: '',
      challengeAnimationFinished: false,
      showStartButton: true,
      autoPlaySuccess: false,
      allowOSK: false,
      showOSK: false,
      inputSetupRaw: null,
      alreadyTriedWithoutStartButton: false,
      gamepadSelectButtonBehaviour: "OPEN_MENU",
    };

    this.componentUnmounted = false;
  }

  async componentDidMount() {

    this.remoteVideo = document.getElementById('remoteVideo');
    this.remoteAudio = document.getElementById('remoteAudio');

    this.videoOutputTarget = this.postprocessing ?
      this.videoPostprocessingCanvas.current :
      this.remoteVideo;

      try {
        await this.connectRTCDirectly(false);
      } catch (error) {
        try {
          this.endCall();
          await this.connectRTCDirectly(true);
        } catch (error) {
          this.endCall();
          // Both attempts failed, handle the error
          console.log('Failed to connect to RTC using both legacy = false and legacy = true');
          return;
        }
      }

    this.startCurrentPositionTimer();

    const {
      dispatch,
      lastSessionObject
    } = this.props;

    if (lastSessionObject && lastSessionObject.tournamentId) {
      dispatch(FetchTournamentsDetailAction(lastSessionObject.tournamentId));
    }

    // we make sure that the inputManager back function does nothing
    // because we are handling the pause menu by ourself
    // (onEscapeKeyPressed and onVendorButtonPressed)
    inputManager.setBackFunction(() => {});
    inputManager.setBackSound(false);

    // if the autoplay fails
    // we listen to user inputs to start the video.
    // we can't use the inputManager because it seems
    // that the user input will work only
    // if it happens in the same component (e.g WebRTCGameComponent)
    window.requestAnimationFrame(this.listenForGamepads);
    document.addEventListener('keydown', this.onKeyDown);

    window.addEventListener('gamepad_select', this.onGamepadSelect);
    window.addEventListener('gamepad_stick_button_right', this.toggleOSK);

    // Disabled because the WebRTC Datachannel can not communicate
    // with the server while user is away from tab.
    if (deviceInfo.storeType === STORE_TYPE_SAMSUNG_TV) {
      document.addEventListener("visibilitychange", this.samsungPause);

      const { messageport, application } = require('tizen-common-web');

      //Samsung code
      this.samsungLocalMsgPort = messageport.requestLocalMessagePort("GHEvent");
      console.log("Samsung localport");
      console.log(this.samsungLocalMsgPort);

      this.samsungPortListenerId = this.samsungLocalMsgPort.addMessagePortListener((data, replyPort) => {
        console.log("Tizen addMessagePortListener");
        for (var i = 0; i < data.length; i++) {
          const key = data[i].key;
          const value = data[i].value;
          console.log("Tizen MessagePortListener: " + key + "-" + value);
          if (key == "eventName") {
            switch (value) { // making the key case-insensitive 
              case "pause":
                // input your pauselogic
                if (this) this.samsungPause();
                break;
              case "resume":
                // input your resume logic
                break;
              case "exit":
                application.getCurrentApplication().exit();
                break;
            }
          }
        }
      });
    }
  }

  shouldDisplayStreamingStatistics = () => {
    if (this.props.showLagGraph === 'true' || this.props.showLagGraph === true) {
      return true;
    }

    return false;
  }

  samsungPause = () => {
    console.log("Samsung Pause request recieved");
    this.pause();
  }

  samsungResume = () => {
    console.log("Samsung Resume request recieved NOOP");
  }


  componentDidUpdate() {

    if (!this.state.isLoading && this.props.gameState !== GAME_STATE.PAUSED) {
      inputManager.setBackFunction(() => {});
      KeepAlive.gameRunningReceived();
    }

    if (!this.state.isLoading) {

        if(this.props.gameState === GAME_STATE.PAUSED) {
            // Game is paused
            if(!this.webrtc?.getIsPaused()) {
                this.webrtc?.pause();
            }  
        }
        else {
            // Game is not paused
            if(this.webrtc?.getIsPaused()) {
                this.webrtc?.unpause();
            }  
        } 
    }

    // Check volume is a valid number
    if (typeof this.props.controlPreferencesVolume === 'number' && isFinite(this.props.controlPreferencesVolume)) {
      const normalizedVolume = this.props.controlPreferencesVolume / 100;
      if(this.remoteAudio) {
        this.remoteAudio.volume = normalizedVolume;
      }

      if(this.remoteVideo) {
        this.remoteVideo.volume = normalizedVolume;//(imayo) For backwards compat, audio was in the same stream in OPAL
      }        
    }
    else {

      if(this.remoteAudio) {
        this.remoteAudio.volume = 1;
      }

      if(this.remoteVideo) {
        this.remoteVideo.volume = 1;//(imayo) For backwards compat, audio was in the same stream in OPAL
      } 
    }

    const userData = LocalStorage.readUserData();
    const fixedJoysticks = userData && userData.controlPreferences && userData.controlPreferences.dpadPreference === 'fixed';

    if (fixedJoysticks !== this.fixedJoysticks && this.webrtc) {
      this.webrtc.setFixedJoysticks(fixedJoysticks);
      this.fixedJoysticks = fixedJoysticks;
    }
  }

  componentWillUnmount() {
    this.endCall();
    this.componentUnmounted = true;
  }

  listenForGamepads = () => {

    // we are using lodash's map
    // because getGamepads returns an object in Chrome
    // and an array in other browsers
    map(navigator.getGamepads(), (gamepad) => {
      if (!gamepad) {
        return;
      }

      const buttons = gamepad.buttons;
      const startButtonPressed = buttons[9] && buttons[9].pressed;
      if (buttons[0].pressed || startButtonPressed) {
        this.simulateClick();
      }
    })

    // we keep the listener until the autoplay succeed
    if (!this.state.autoPlaySuccess) {
      window.requestAnimationFrame(this.listenForGamepads);
    }
  }

  simulateClick = () => {
    const mouseClick = new MouseEvent('click', {
      view: window,
      bubbles: true,
    });

    const button = document.getElementById('start-video-button');

    if (button) {
      button.dispatchEvent(mouseClick);
    }
  }

  startCurrentPositionTimer = () => {
    const lastSessionObject = this.props.lastSessionObject;

    if (lastSessionObject && lastSessionObject.tournamentId) {
      if (this.componentUnmounted) return;

      const {
        challengeId,
        tournamentId,
      } = lastSessionObject;

      this.props.dispatch(FetchCurrentPosition(
        getTournamentShortCode(tournamentId, challengeId),
        challengeId,
        tournamentId,
        this.onCurrentPositionFetched
      ))
    }
  }

  onCurrentPositionFetched = (response) => {
    this.setState({ currentRank: response });
    this.currentPositionTimeout = setTimeout(this.startCurrentPositionTimer, 5000);
  }

  connectRTCDirectly = (legacy) => {
    const perfCanvas = this.enablePerfCanvas ? this.perfCanvas.current : null;

    if(this.connectLocalHost) {
        this.webRTCServer = "127-0-0-1.xip.antstream.com";
        this.webSocketProtocol = 'ws://';
        this.webSocketServerPort = 4001;
    }

    if(legacy) { 
      this.webrtc = new LegacyAntRTC(
        this.webRTCServer,
        this.sessionId,
        this.remoteVideo,
        this.videoOutputTarget,
        this.postprocessing ? this.videoPostprocessingCanvas.current : null,
        this.postprocessing ? this.superSamplingCanvas.current : null,
        this.controlsCanvas.current,
        perfCanvas,
        this.props.challengeStyle,
        this.onVideoSetup,
        this.onSaveResponse,
        this.onErrorCb,
        this.inGameEvent,
        this.mute,
        this.fixedJoysticks,
        this.isUIPaused,
        this.onPauseRequest,
        this.gameId,
        this.serverIP,
      );
    }
    else {
      var gpuDemoGame = null;

      if (this.props.gameId == "0155cbf1-8008-44a9-a0c4-7a71a36b292e") {
        gpuDemoGame = {
          steamID: 479030,
          windowName: "DOOMx64 Demo",
        };
      }
      else if (this.props.gameId == "b6b7e666-704c-4852-a75d-b8f79884edb1") {
        gpuDemoGame = {
          steamID: 588650,
          windowName: "Dead Cells",
        };
      }
      else if (this.props.gameId == "52dc0716-1837-41a7-a5a2-26c0d1edfa1d") {
        gpuDemoGame = {
          steamID: 227420,
          windowName: "Giana Sisters - Twisted Dreams",
        };
      }

      if (gpuDemoGame !== null) {
        this.webrtc = new AntRTC_Demo(
          this.webRTCServer,
          this.webSocketServerPort,
          //this.sessionId,
          "GPUDemoFakeSessionID",
          this.remoteVideo,
          this.remoteAudio,
          this.videoOutputTarget,
          this.postprocessing ? this.videoPostprocessingCanvas.current : null,
          this.postprocessing ? this.superSamplingCanvas.current : null,
          this.controlsCanvas.current,
          perfCanvas,
          this.props.challengeStyle,
          this.onVideoSetup,
          this.onSaveResponse,
          this.onErrorCb,
          this.inGameEvent,
          this.mute,
          this.fixedJoysticks,
          this.isUIPaused,
          this.onPauseRequest,
          gpuDemoGame,
          this.gameId,
          this.serverIP,
        );
      }
      else {
        this.webrtc = new AntRTC(
          this.webRTCServer,
          this.webSocketServerPort,
          this.webSocketProtocol,
          this.sessionId,
          this.remoteVideo,
          this.remoteAudio,
          this.videoOutputTarget,
          this.postprocessing ? this.videoPostprocessingCanvas.current : null,
          this.postprocessing ? this.superSamplingCanvas.current : null,
          this.controlsCanvas.current,
          perfCanvas,
          this.props.challengeStyle,
          this.onVideoSetup,
          this.onSaveResponse,
          this.onErrorCb,
          this.inGameEvent,
          this.mute,
          this.fixedJoysticks,
          this.isUIPaused,
          this.onPauseRequest,
          this.gameId,
          this.serverIP,
        );
      }
    }

    this.webrtc.onVendorButtonPressed = this.onVendorButtonPressed;
    this.webrtc.onEscapeKeyPressed = this.onEscapeKeyPressed;
    this.webrtc.onTabKeyPressed = this.toggleOSK;

    return this.beginCall();
  }

  onPauseRequest = () => {
    this.pause();
  }

  onVendorButtonPressed = () => {
    this.togglePause();
  }

  onEscapeKeyPressed = () => {
    // do not toggle pause until challenge animation is finished
    if (this.isChallenge() && !this.state.challengeAnimationFinished) return;

    this.togglePause();
  }

  onVideoPlaySuccess = () => {
    this.state.showStartButton = false;
    this.state.autoPlaySuccess = true;
  }

  onVideoPlayError = (error) => {
   
    // no need to show additional start button for the first time
    // show it only if an error happens multiple times
    if (this.state.alreadyTriedWithoutStartButton === false) {
      // show additional start button
      this.remoteVideo.pause();
      this.state.showStartButton = true;
      
    } else {
      // or just start playing
      this.beginVideo();
      this.state.alreadyTriedWithoutStartButton = true;   
    }
  }

  onAudioPlayError = (error) => {

    // no need to show additional start button for the first time
    // show it only if an error happens multiple times
    if (this.state.alreadyTriedWithoutStartButton === false) {
      // show additional start button
      this.remoteAudio.pause();
      this.state.showStartButton = true;    
    } else {
      // or just start playing
      this.beginVideo();
      this.state.alreadyTriedWithoutStartButton = true;   
    }
  }

  beginVideo = () => {
    if(this.remoteVideo.paused || this.remoteVideo.ended) {
      this.remoteVideo.play().then(this.onVideoPlaySuccess).catch(this.onVideoPlayError);
    }

    if(this.remoteAudio.paused || this.remoteAudio.ended) {
      this.remoteAudio.play().catch(this.onAudioPlayError);
    }
  }

  beginCall = () => {

    this.setState({ showStartButton: false, autoPlaySuccess: true });

    if(this.timeoutCallNotStartedId) {
      clearTimeout(this.timeoutCallNotStartedId);
    }

    this.timeoutCallNotStartedId = setTimeout(this.endCall, 12000);

    // Return a promise that resolves when the call is successfully begun, or rejects if it fails
    return this.webrtc
      .call()
      .then(() => {
        clearTimeout(this.timeoutCallNotStartedId);
        this.timeoutCallNotStartedId = null;
      })
      .catch(() => {
        console.warning(`[WEBRTC GAME] beginCall failed`);
        clearTimeout(this.timeoutCallNotStartedId);
        this.timeoutCallNotStartedId = null;
  
        // Reject the promise with the error message
        throw new Error('Failed to begin call');
      });
  };

  endCall = () => {
    if(this.webrtc) {
      this.webrtc.endCall();
      this.webrtc = null;
    }

    inputManager.setBackFunction(null);
    inputManager.setBackSound(true);
    clearTimeout(this.currentPositionTimeout);

    document.removeEventListener('keydown', this.onKeyDown);

    window.removeEventListener('gamepad_select', this.onGamepadSelect);
    window.removeEventListener('gamepad_stick_button_right', this.toggleOSK);
    document.removeEventListener("visibilitychange", this.pause);
    this.removeSamsungListener();

    // TODO duplicated with native gamefinished
    KeepAlive.gameFinishedReceived();
    this.props.dispatch(SetGameFinished());    
  }

  removeSamsungListener = () => {
    if (deviceInfo.storeType !== STORE_TYPE_SAMSUNG_TV) return;
    if (!this.samsungMessagePortListenerId) return;
    if (!this.samsungLocalMsgPort) return;

    /**
     * @link https://www.tizen.org/tv/web_device_api/messageport#::MessagePort::LocalMessagePort::removeMessagePortListener
     **/
    this.samsungLocalMsgPort.removeMessagePortListener(this.samsungMessagePortListenerId);
  };

  onVideoSetup = () => {
    this.setState({ isLoading: false });
    if (this.isChallenge()) {
      this.setState({ challengeAnim: 'start' });
      this.pause();
    }
    this.beginVideo();
  }

  /**
   * resp should be like:
   * {
   *  error: 0,
   *  message: "",
   *  player: 0,
   *  type: "CG_PACKET_SAVE_RESPONSE"
   * }
   *
   */
  onSaveResponse = (resp) => {
    // If there is no error,
    // we use FetchGameDetailed
    // to update the saving state.
    // It would have been better to directly
    // dispatch SaveStateAction(gameId, savedStates)
    // but resp doesn't contain the savedStates.
    if (resp.error === 0) {
      this.props.dispatch(FetchGameDetailed(this.props.gameId));
    }

    // If there is an error,
    // we let the InGameMenu component
    // display a GenericPopup with the message:
    // 'Oops! Error saving'
    // 'Please try saving again.'
    // If we want to be more precise,
    // we could try using resp.message.
  }

  onChallengeStartAnimFinish = () => {
    this.setState({ challengeAnim: '', challengeAnimationFinished: true });
    this.unpause();
    this.webrtc.tryUnmute();
  }

  onChallengeEndAnimFinish = () => {
    this.endCall();
    this.props.dispatch(SetGameQuit(this.props.sessionId));
    navigateToLocation(ROUTES.RESULTS_WAITING);
  }

  onErrorCb = (message, errorObject) => {
    var errormessage = null;
    if (message && message.length > 0) {
      errormessage = message;
    }
    else {
      errormessage = "The was an issue with your network. The connection to the stream was lost."
    }
    
    Elastic.logDirect(errormessage, errorObject, LOG_LEVEL.ERROR);
    this.setState({ isLoading: false });
    this.endCall();
    this.props.dispatch(SetGameQuit(this.props.sessionId));

    if (this.state.isPopupActive) return;
    this.setState({ isPopupActive: true });

    addPopup(
      <GenericPopup
      okButtonLabel="Got it!"
      title="Something's gone wrong!"
      message={ errormessage }
        onOkClicked={() => {
          this.setState({ isPopupActive: false });
          navigateBack();
        }}
      />
    );
  }

  inGameEvent = (data) => {
    if (data.event === "CHA") {
      // TODO: This code should be shared with the native message in game-wrapper.js

      // TO DO, we need something generic to handle closing connections that can be reused elsewhere
      this.setState({ challengeAnim: 'end' });

      // Fade out video
      this.videoOutputTarget.style.transition = "opacity 3s ease-out 0s";
      this.videoOutputTarget.style.opacity = 0;

      if(this.webrtc) {
        //Don't let user continue input after challenge ended
        this.webrtc.disableInput(true);
      }
    }
    else if (data.event === "ERR") {
      console.error(data);
      this.onErrorCb("Reporting LUA Scripting Error (N15)", data);
    }
    else if (data.event === "TIM") {
      console.error(data);
      this.onErrorCb("Server closed connection because client was inactive. (N16)", data);
    }
    else if (data.type === PacketTypeString.CG_PACKET_DATA_DISPLAY_SETUP) {
      this.setScoreTitle(data);
    }
    else if (data.type === "HUD UPDATE") {
      this.setScoreValue(data);
    }
    else if (data.type === PacketTypeString.CG_PACKET_INPUT_SETUP_RAW) {

        if(data.flags & InputSetupRawFlags.BACK_BUTTON_IS_COIN) {
            this.setState({
                gamepadSelectButtonBehaviour:  "NONE"
            });
        }

        if (data.vk_enabled && data.vk_enabled.find(entry => entry > 0)) {
            if(this.webrtc?.setEnableVirtualKeyboard) {
                this.webrtc.setEnableVirtualKeyboard(true);
            }
            // vk_enabled is not empty, allow OSK
            this.setState({
                allowOSK: true,
                inputSetupRaw: data
            });
        }
    }
  }

  setScoreTitle = (data) => {
    if (data.index) {
      const scores = this.state.scores.slice();
      const index = data.index - 1;

      let defaultValue = '';
      switch (data.display_type) {
        case CG_DATA_DISPLAY_TYPES.CG_DATA_DISPLAY_TYPE_STRING:
          defaultValue = "";
          break;
        case CG_DATA_DISPLAY_TYPES.CG_DATA_DISPLAY_TYPE_TIME_A:
        case CG_DATA_DISPLAY_TYPES.CG_DATA_DISPLAY_TYPE_TIME_D:
          defaultValue = "00:00";
          break;
        case CG_DATA_DISPLAY_TYPES.CG_DATA_DISPLAY_TYPE_INT:
          defaultValue = 0;
          break;
        default:
          defaultValue = '';
      }

      if (scores[index]) {
        scores[index].title = data.title;
        scores[index].value = defaultValue;
      } else {
        scores[index] = {
          title: data.title,
          value: defaultValue
        }
      }
      this.setState({ scores })
    }
  }

  setScoreValue = (data) => {
    if (data.index) {
      const scores = this.state.scores.slice();
      const index = data.index - 1;
      let value = 0;

      if (data.integer !== undefined) {
        value = data.integer;
      } else if (data.time !== undefined) {
        value = this.convertMsToString(data.time);
      }else if (data.string !== undefined) {
        value = data.string;
      }

      if (scores[index]) {
        scores[index].value = value;
      } else {
        scores[index] = {
          value: value
        }
      }

      this.setState({ scores })
    }
  }

  convertMsToString = (ms) => {
    const d = new Date(ms)
    const min = d.getMinutes().toString().padStart(2, '0')
    const seconds = d.getSeconds().toString().padStart(2, '0')
    return `${min}:${seconds}`
  }

  mute = (value) => {
    if(this.remoteAudio)
    {
      this.remoteAudio.muted = value;
    }

    //(imayo) For backwards compat, audio was in the same stream in OPAL
    if(this.remoteVideo)
    {
      this.remoteVideo.muted = value;
    }
  }

  isUIPaused = () => {
    return this.props.gameState === GAME_STATE.PAUSED;
  }

  isChallenge = () => {
    const challengeStyle = this.props.challengeStyle;
    return challengeStyle && challengeStyle !== 'test';
  }

  isTournament = () => {
    const challengeStyle = this.props.challengeStyle;
    return challengeStyle && challengeStyle === 'tournament';
  }

  onGamepadSelect = () => {
    if(this.state.gamepadSelectButtonBehaviour === "OPEN_MENU") {
      // do not toggle pause until challenge animation is finished
      if (this.isChallenge() && !this.state.challengeAnimationFinished) return;
        this.togglePause();
    }  
  }

  togglePause = () => {
    if (this.isUIPaused()) {
      inputManager.setBackSound(false);
      this.unpause();
    } else {
        inputManager.setBackSound(true);
        this.hideOSK();
        this.pause();
    }
  }

  toggleOSK = () => {
    if (this.isUIPaused() || !this.state.inputSetupRaw) {
      return;
    }

    if(this.webrtc) {
        this.webrtc.setOSKVisible(!this.state.showOSK);
    }
       
    this.setState({ showOSK: !this.state.showOSK });
  }

  hideOSK = () => {
    if(this.webrtc) {
        this.webrtc.setOSKVisible(false);
    }

    if (this.state.showOSK === true) {
      this.setState({ showOSK: false });
    }
  }

  onStartClicked = () => {
    this.beginVideo();
    if(this.webrtc) {
      this.webrtc.startGame();
    }
    
  }

  onMenuClicked = () => {
    this.hideOSK();
    this.pause();
  }

  inGameHeader = () => {
    // we don't show the header
    // if the game is still loading or the autoplay failed
    if (this.state.isLoading || !this.state.autoPlaySuccess) {
      return;
    }

    const active = !this.isUIPaused() && this.state.showHeader;
    const isBetterRank = this.state.currentRank >= this.props.prevRank;

    const giantSlayerRole = this.props.lastSessionObject ? this.props.lastSessionObject.role : "";

    return (
      <InGameHeader
        allowOSK={this.state.allowOSK}
        onMenuClicked={ this.onMenuClicked }
        onStartClicked={ this.onStartClicked }
        onOSKClicked={ this.toggleOSK }
        active={ active }
        isChallenge={ this.isChallenge() }
        scores={ this.state.scores || [] }
        userData={ this.props.user }
        isTournament={ this.isTournament() }
        rank={ this.state.currentRank }
        isBetterRank={ isBetterRank }
        giantSlayerRole={ giantSlayerRole }
        giantSlayerChallenge={ this.props.giantSlayerChallenge }
        giantUserData={ this.props.giantUserData }
        challengeObject={ this.props.challengeObject }
      />
    );
  }

  inGameOSK = () => {
    if (!this.state.showOSK || !this.state.inputSetupRaw) {
      return null;
    }

    return (
      <InGameOSK
        inputSetupRaw={this.state.inputSetupRaw}
        onOSKKeyDown={ (code) => {
          this.webrtc.network.sendRawKeyInput(code, 1);
        } }
        onOSKKeyUp={ (code) => {
          this.webrtc.network.sendRawKeyInput(code, 0);
        } }
      />
    );
  }

  onSendSave = (data) => {
    if(this.webrtc) {
        this.webrtc.sendSave(data);
    }
  }

  inGameMenu = () => {
    if (this.props.gameState === GAME_STATE.PAUSED && this.state.challengeAnim === '') {
      
      // do not try to render menu until animation is finished
      if (this.isChallenge() && !this.state.challengeAnimationFinished) return null;

      return (
        <InGameComponent
          webrtc={{
            sendSave: this.onSendSave,
          }}
        />
      );
    }

    return <></>;
  }

  inGameChallengeAnimation = () => {
    if (this.isChallenge() && this.state.autoPlaySuccess) {
      return (
        <ChallengeAnimation
          challengeAnim={this.state.challengeAnim}
          onChallengeStartAnimFinish={this.onChallengeStartAnimFinish}
          onChallengeEndAnimFinish={this.onChallengeEndAnimFinish}
        />
      )
    }

    return <></>;
  }

  pause = () => {
    if (!this.isUIPaused()) {

      if(this.webrtc && !this.webrtc.getIsPaused()) {
        this.webrtc?.pause();
      }
   
      KeepAlive.gamePausedReceived();
      this.props.dispatch(PauseGameAction());
    }
  }

  unpause = () => {  
    if(this.webrtc && this.webrtc.getIsPaused()) {
        this.webrtc?.unpause();
        this.props.dispatch(UnpauseGameAction());
    }
  }

  showHeader = throttle(() => {
    clearTimeout(this.showHeaderTimeout);
    this.setState({ showHeader: true });
    this.showHeaderTimeout = setTimeout(() => {
      this.setState({ showHeader: false });
    }, 2000);
  }, 1000);

  onStartVideoClicked = () => {
    this.state.showStartButton = false;
    this.beginVideo();
    if(this.webrtc){
      this.webrtc.startGame();
    }
  }

  startVideoButton = () => {
    if(this.state.showStartButton) {
      return (
        <div className="start-video-container">
          <button
            id="start-video-button"
            onClick={ this.onStartVideoClicked }>
              <div className="content"> Start </div>
          </button>
        </div>
      );
    }
    return <></>;
  }

  onKeyDown = (event) => {
    if (!this.state.showStartButton) {
      return;
    }

    if (this.state.autoPlaySuccess) {
      document.removeEventListener('keydown', this.onKeyDown);
      return;
    }

    if (event.code === 'Enter') {
      this.onStartVideoClicked();
    }
  }

  renderSponsoredBanner = (position) => {
    if (!this.videoOutputTarget) return;

    const { isSponsoredTournament, tournamentVisualAssets, tournamentAssets } = this.props;
    if (!isSponsoredTournament || !tournamentVisualAssets) return;
    const { gameplayBackgroundLeft, gameplayBackgroundRight } = tournamentVisualAssets;

    let imgUrl = position === 'left' ? gameplayBackgroundLeft : gameplayBackgroundRight;

    // if not absolute path
    if (typeof imgUrl === 'string' && !imgUrl.includes('https://')) {
      imgUrl = tournamentAssets + imgUrl;
    }

    const videoWidth = parseInt(this.videoOutputTarget.style.width, 10);
    const videoMarginLeft = parseInt(this.videoOutputTarget.style.marginLeft, 10);
    if (!videoWidth || !videoMarginLeft) return;

    const style = {
      backgroundImage: `url(${imgUrl})`,
      width: videoMarginLeft + 'px',
      marginLeft: position === 'left' ? 0 : videoMarginLeft + videoWidth + 'px'
    };

    return (
        <div
            className={`sponsorBanner sponsorBanner--${position}`}
            style={style}
        />
    );
  }

  render() {
    const displayGameArea = this.props.showHowToPlay ? 'none' : 'block';
    const cursor = this.state.showHeader ? 'auto' : 'none';

    let controlsOpacity = 1;
    if (this.props.controlPreferencesTransparency) {
      controlsOpacity = 1 - (this.props.controlPreferencesTransparency / 100);
    }

    const videoHTML = "<video id='remoteVideo' className='remote-video' muted paused autoplay playsinline></video>";
    const audioHTML = "<audio id='remoteAudio' className='remote-audio' muted paused autoplay playsinline></audio>";

    return(
      <div
        className="webrtc-game-component"
        onMouseMove={ this.showHeader }
        onTouchStart={ this.showHeader }
        style={{ cursor }}>
        { this.inGameHeader() }

        { this.inGameOSK() }

        { this.inGameMenu() }

        { this.inGameChallengeAnimation() }

        { this.startVideoButton() }

        <div className='loaderContainer'>
          <Loader loading={ this.state.isLoading } />
        </div>

        <div style={{ display: displayGameArea }}>
          <div>
            {(
              <canvas
                id="perfCanvas"
                ref={this.perfCanvas}
                width="200"
                height="100"
                style={{
                  zIndex: 101,
                  border: 0,
                  top: 0,
                  left: 0,
                  left: '25px',
                  top: '100px',
                  position: 'absolute'
                }}
              ></canvas>
            )}

            <canvas
              id="controlsCanvas"
              ref={ this.controlsCanvas }
              width="200"
              height="200"
              style={{
                zIndex: 99,
                border: 0,
                top: 0,
                left: 0,
                position: "absolute",
                opacity: controlsOpacity,
              }}>
            </canvas>

            {this.postprocessing && (
              <div>
                <canvas
                    id="videoPostprocessingCanvas"
                    ref={this.videoPostprocessingCanvas}
                    width="200"
                    height="200"
                    style={{
                        zIndex: 98,
                        border: 0,
                        top: 0,
                        left: 0,
                        position: 'absolute'
                    }}
                ></canvas>

                <canvas
                    id="superSamplingCanvas"
                    ref={this.superSamplingCanvas}
                    width="200"
                    height="200"
                    style={{
                        zIndex: 1,
                        border: 0,
                        top: 0,
                        left: 0,
                        width: 0,
                        height: 0,
                        position: 'absolute'
                    }}
                ></canvas>
                <div dangerouslySetInnerHTML={{ __html: videoHTML }} />
              </div>
            )}

            <div dangerouslySetInnerHTML={{ __html: audioHTML }} />

            {!this.postprocessing && (
              <div>
                {this.renderSponsoredBanner('left')}
                <video
                  id="remoteVideo"
                  ref={ this.remoteVideoReactRef }
                  src={ blackVideo }
                  className="remote-video"
                  muted
                  autoPlay
                  playsInline>
                </video>
                {this.renderSponsoredBanner('right')}
              </div>
            )}

          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const gameId = getCurrentGameDetailsId(state);
  const gameState = getGameState(state);
  const controlPreferencesVolume = getControlPreferencesVolume(state);
  const controlPreferencesTransparency = getControlPreferencesTransparency(state);
  const showHowToPlay = state.gameDetails ? state.gameDetails.showHowToPlay : false;
  const user = getLoggedUser(state);
  const lastSessionObject = getLastSessionObject(state);
  const leaderBoardObject = getLeaderBoardObject(state);
  const prevRank = leaderBoardObject ? leaderBoardObject.rank : -1;

  const giantSlayerChallenge = getSelectedGiantSlayerChallenge(state);
  const giants = getGiants(state);

  let giantUserData = null;
	let challengeObject = null;

  if (giantSlayerChallenge) {
    giantUserData = giants.find(giant => giant._id === giantSlayerChallenge.giantId);
    challengeObject = getChallengeById(state, giantSlayerChallenge.challengeId);
  }
  const tournamentData = getCurrentTournamentData(state);

  const routerQuery = paramsToObject(ownProps.location.search);

  return {
    showLagGraph: state.settings.show_lag_graph,
    tournamentAssets: getAssetsUrlWithFolder(state, FOLDER_TYPES.TOURNAMENTS),
    isSponsoredTournament: tournamentData && tournamentData.sponsored,
    tournamentVisualAssets: tournamentData && tournamentData.sponsoredData && tournamentData.sponsoredData.visualAssets,
    challengeStyle: getRunningGameChallengeStyle(state),
    gameId,
    gameState,
    controlPreferencesVolume,
    controlPreferencesTransparency,
    lastSessionObject,
    prevRank,
    serverIP: routerQuery.serverIP,
    sessionId: routerQuery.sessionId,
    serverPort: routerQuery.serverPort,
    webSocketServerPort: routerQuery.webSocketServerPort,
    showHowToPlay,
    user,
    giantSlayerChallenge,
    giantUserData,
    challengeObject,
  };
}

const WebRTCGameComponent = connect(mapStateToProps)(_WebRTCGameComponent);

export default WebRTCGameComponent;
