import React from 'react';
import 'rxjs/add/operator/filter';
import {get} from 'lodash';
import {GetGiantSlayerChallengesAction} from './components/challenge/challenge.actions';
import {ShowNotificationAction} from './components/notifications/notifications.actions';
import {updateExpAnimationData, updateGemAnimationData} from './components/user-header/user-header.component';
import {
    FetchPaymentDataActionSuccess,
    SET_USER_AVATAR_ACTION
} from './components/login/login.actions';
import {SetConnectionTesterSupported} from './components/settings/settings.actions';
import RewardPopup from './components/popup/reward-popup/reward-popup.component';
import {addPopup, TYPE_QUIT, TYPE_REWARD} from './components/popup/popup.component';
import GenericPopup, {
    TYPE_HAS_TWO_BUTTONS,
    MESSAGE_SIZE,
} from './components/popup/generic-popup/generic-popup.component';
import NewContentPopup from './components/popup/new-content-popup/new-content-popup.component';
import {
    bringToFront,
    clearLoginCredentials,
    configureLogging,
    openUrlOnDefaultBrowser,
    quitApp, sendAchivementUpdateToNative,
    sendRemoteUpdateList,
    startNativePayment
} from './assets/lib/game-wrapper';
import {antstreamAPIService, antstreamService} from './app.reducer';
import {isDeviceWithNativePayments} from './app.helpers';
import {FetchMessageCountActionSuccess, onTournamentEndedMessage} from './entities/messages/messages.actions';
import deviceInfo, { isDeviceSafari, isDeviceFirefox, isDeviceIphone } from './assets/lib/deviceInfo';
import featureInfo, {FEATURES} from './assets/lib/featureInfo';
import QuitGamePopup from './components/popup/quit-game-popup/quit-game-popup.component';
import {
    GameLeaderboardUpdatedAction,
    SaveStateAction,
    UPDATE_FREE_TIER_INFORMATION
} from './components/game-details/game-details.actions';
import {
    getRandomObjectInArray,
    handleNoAuthTokenIssue,
    isFreeTierV2FeatureFlagEnabled,
    withBadWordMask
} from './assets/lib/utils';
import {NOTIFICATION_MESSAGES, APPLE_IOS_SKU} from './constants';
import germanyAgeIcons from './assets/images/germany-age-icons';
import {navigateBack, navigateToLocation, ROUTES} from './app.router';
import {MESSAGE_TYPES} from './assets/lib/MessageTypes';
import {getTranslationTemplate} from "./assets/lib/TranslationManager";
import {SetGTMEvent, TYPE_INITIAL_PURCHASE} from "./gtm.actions";
import { webSocketErrorHandling } from './assets/lib/ErrorTypes';
import {externalFocusableComponent as externalFocusableFreeTierComponent} from './components/free-tier-information/free-tier-information.component'
import GemCreditPopup from './components/popup/gem-credit-popup/gem-credit-popup.component';
import {ChangeProfileImageActionSuccess} from './entities/users/users.actions';
import {clearAll, readRestUserData} from "./assets/lib/local-storage";
import {translate} from "./components/common/translation/Translation";
import {TRANS_NAMESPACES} from "./assets/lib/i18n";
import { STORE_TYPE_SAMSUNG_TV } from './assets/lib/deviceInfo';
import { getUserIsSubscribed } from './app.selectors';

export const AppInitAction = () => {
    return (dispatch) => {
        dispatch({type: 'App Init Action'});
        antstreamService
            .getIsInitialised$()
            .filter(resp => resp === true)
            .subscribe(() => dispatch(AppInitActionSuccess()));
    };
};

let currencyGemsUpdateCount = 0;
export const nextGemsUpdateId = () => {
    return currencyGemsUpdateCount++;
};

export const onGemsUpdateMessage = (dispatch, response) => {
    const { amount } = response;
    dispatch(SetGemsAction(amount));
    const gemsObject = {
        gemsUpdateId: nextGemsUpdateId(),
        amount:amount
    };
    updateGemAnimationData(gemsObject);
};

export const onExpUpdateMessage = (dispatch, response) => {
    const { expObject } = response;
    dispatch(SetExpAction(expObject));
    updateExpAnimationData(expObject);
};

export const onLeaderboardUpdateMessage = (dispatch, gameId) => {
    dispatch(GameLeaderboardUpdatedAction(gameId));
};

export const onRewardMessage = (dispatch,response) => {
    const {totalGems, expObject} = response;

    const gemsObject = {
        gemsUpdateId: nextGemsUpdateId(),
        amount:response.totalGems
    };
    response.gemsObject = gemsObject;
    addPopup(
        <RewardPopup
            response={response}
        />
        ,{
            type: TYPE_REWARD,
            doNotShowInInternalPopupsOnlyMode: true // show this popup only AFTER playing game
        }
    );

    if(totalGems)dispatch(SetGemsAction(totalGems));
    if(expObject)dispatch(SetExpAction(expObject));
};

const SetNewContentMessageRead = (payload) => {
    const { authToken } = readRestUserData() || {};
    if (!authToken) handleNoAuthTokenIssue('SetNewContentMessageRead');

        const params = {
            body: { contentId: payload.id },
            headers: { Authorization: 'Bearer ' + authToken }
        };

        antstreamAPIService.messages.alertReadCreate(params).then(({ data }) => {
            if (!data || !data.result) throw new Error('Something went wrong');
            if (data.error) throw new Error(data.error);
        }).catch(catchErr => {
            console.error('Failed to read new content message: ', catchErr);
        });

};

const onPaymentUpdateMessage = (dispatch, resp) => {
    dispatch(FetchPaymentDataActionSuccess(resp.data));
    bringToFront();
};

const onPaymentSuccessful = (data) => {
    const {paymentType, sku} = data;
    // 'stripe' payments GTM dataLayer events are sent from the payment-web
    // app, so don't need to handle those.
    if(paymentType!=='stripe') {
        const event = {
            event: "checkoutComplete",
            ecommerce: {
                purchase: {
                    currencyCode: "GBP",
                    products: [{
                        name: sku,
                        id: sku
                    }]
                }
            }
        };
        SetGTMEvent(TYPE_INITIAL_PURCHASE, event);
    }
};

const waysunPendingPaymentResultPopup = (subscriptioStatus) => {
    if(subscriptioStatus){ // successful china mobile payment
        addPopup(<GenericPopup // to be triggered on payment data message
            okButtonLabel="Ok"
            title="Subscription Success"
            message="A Subscription has been created"
            onOkClicked={() => {
                navigateToLocation(ROUTES.HOMEPAGE);
            }}
        />);
    }// failed china mobile payment
    else {
        addPopup(<GenericPopup
            okButtonLabel="Got it!"
            title="Payment Failed"
            message="Please try again later"
            onOkClicked={() => {
                navigateToLocation(ROUTES.HOMEPAGE);
            }}
        />);
    }
}

export const ListenApiWebsocket = () => {
    return (dispatch, getState) => {
        dispatch({type: 'Listen To API Websocket Action'});
        antstreamService.getWebsocketAPImessage$().subscribe((resp) => {
            console.log('ListenApiWebsocket resp: ', resp);
            // Handle all the errors coming into the websocket here!
            // if(resp.error) {webSocketErrorHandling(resp.error, dispatch, getState)}

            const messageClass = get(resp, '@class', '') || '';
            switch (messageClass) {
                case '.ChallengeIssuedMessage':
                    dispatch(FetchMessageCountActionSuccess(1));
                    dispatch(ShowNotificationAction(
                        `You've been challenged!`,
                        getRandomObjectInArray((deviceInfo.isXboxPlatform() || deviceInfo.isPlayStationPlatform())
                            ? NOTIFICATION_MESSAGES.ChallengeIssuedMessageNoClick
                            : NOTIFICATION_MESSAGES.ChallengeIssuedMessage
                        ),
                        null,
                        null,
                        'message'
                    ));
                    break;
                case '.ChallengeStartedMessage':
                    dispatch(ShowNotificationAction(
                        `Challenge accepted!`,
                        getRandomObjectInArray(NOTIFICATION_MESSAGES.ChallengeStartedMessage)
                    ));
                    break;
                case '.ChallengeDrawnMessage':
                    dispatch(FetchMessageCountActionSuccess(1));
                    dispatch(ShowNotificationAction(
                        `It's a draw!`,
                        getRandomObjectInArray((deviceInfo.isXboxPlatform() || deviceInfo.isPlayStationPlatform())
                            ? NOTIFICATION_MESSAGES.ChallengeDrawnMessageNoClick
                            : NOTIFICATION_MESSAGES.ChallengeDrawnMessage),
                        null,
                        null,
                        'message'
                    ));
                    // antstreamService.syncGems();
                    break;
                case '.ChallengeWonMessage':
                    dispatch(FetchMessageCountActionSuccess(1));
                    dispatch(ShowNotificationAction(
                        `Challenge Won!`,
                        getRandomObjectInArray(NOTIFICATION_MESSAGES.ChallengeWonMessage),
                        null,
                        null,
                        'message'
                    ));
                    // antstreamService.syncGems();
                    break;
                case '.ChallengeLostMessage':
                    dispatch(FetchMessageCountActionSuccess(1));
                    dispatch(ShowNotificationAction(
                        `Challenge Lost!`,
                        getRandomObjectInArray((deviceInfo.isXboxPlatform() || deviceInfo.isPlayStationPlatform())
                            ? NOTIFICATION_MESSAGES.ChallengeLostMessageNoClick
                            : NOTIFICATION_MESSAGES.ChallengeLostMessage),
                        null,
                        null,
                        'message'
                    ));
                    // antstreamService.syncGems();
                    break;
                case '.ScriptMessage': {
                    if (resp.scriptData?.type === 'gems-update') {
                        onGemsUpdateMessage(dispatch, {amount: resp.scriptData.data});
                    } else if (resp.scriptData?.type === MESSAGE_TYPES.TOURNAMENT_ENDED) {
                        const parsed = JSON.parse(resp.scriptData.data);
                        const tournamentTitle = parsed.data.result.tournamentObject.title;
                        dispatch(onTournamentEndedMessage(parsed.data.result));
                        dispatch(ShowNotificationAction(
                            "TOURNAMENT OVER!",
                            `${tournamentTitle}. Check how you did`
                        ));
                    } else if (resp.scriptData?.type === MESSAGE_TYPES.TOURNAMENT_ONE_DAY || resp.scriptData?.type === MESSAGE_TYPES.TOURNAMENT_STARTED ) {
                        const parsed = JSON.parse(resp.scriptData.data);
                        dispatch(FetchMessageCountActionSuccess(1));
                        if (resp.scriptData?.type === MESSAGE_TYPES.TOURNAMENT_ONE_DAY) {
                            const tournamentTitle = parsed.data.result.tournamentObject.title;
                            dispatch(ShowNotificationAction(
                                "TOURNAMENT 24HR COUNTDOWN!",
                                `${tournamentTitle}. Starts tomorrow!`,
                                null,
                                null,
                                'message'
                            ));
                        }
                    }
                    break;
                }
                case '.LogEventResponse': {
                    if (!resp || !resp.scriptData || !resp.scriptData.data) break;

                    let parsed = null;
                    try {
                        parsed = JSON.parse(resp.scriptData.data);
                    } catch (e) {
                        console.error('Failed to parse ws message: ', e.message);
                        break;
                    }
                    if (parsed && parsed.type === 'payment_update') {
                        if (typeof parsed.data === 'string') {
                            try {
                                parsed = JSON.parse(parsed.data);
                            } catch (e) {
                                console.error('Failed to parse payment_update message');
                            }
                        }

                        const service = get(parsed, 'service', '');
                        if(service && service === 'waysun') {
                            const subscriptionUpdateResult = get(parsed, 'paymentData.subscription', '');
                            waysunPendingPaymentResultPopup(subscriptionUpdateResult)
                        }
                        onPaymentUpdateMessage(dispatch, {data: parsed});
                    } else if (resp.scriptData.type === 'updateAchievement') {
                        sendAchivementUpdateToNative(parsed);
                    } else if (parsed && parsed.type === 'gems-update') {
                        onGemsUpdateMessage(dispatch, parsed);
                    } else if (parsed && parsed.type === 'ReloadGiantSlayerChallengesList') {
                        if (getState().routing.currentRoute === ROUTES.HOMEPAGE) {
                            dispatch(GetGiantSlayerChallengesAction());
                        }
                    } else if (parsed && parsed.type === 'profile-image-update') {
                        dispatch({ type: SET_USER_AVATAR_ACTION, payload: parsed.profile_image_id }); // update avatar in header
                        dispatch(ChangeProfileImageActionSuccess(parsed.profile_image_id));
                    } else if (parsed && parsed.type === 'new_content') {
                        const isFreeTierV2FeatureEnabled = isFreeTierV2FeatureFlagEnabled(getState());
                        if (isFreeTierV2FeatureEnabled && parsed && parsed.title === 'Welcome to Antstream!') {
                            return; // do not show old welcome pop-up for Freemium V2
                        }

                        // do not show welcome free tier pop-up if subscribed
                        if (deviceInfo.isPlayStationPlatform() && getUserIsSubscribed(getState())) {
                            return;
                        }

                        addPopup(
                            <NewContentPopup
                                imgSrc={parsed.img_src}
                                title={parsed.title}
                                message={parsed.message}
                                onOkClicked={() => {
                                    try {
                                        SetNewContentMessageRead({id: parsed.id})
                                    } catch (newContentErr) {
                                        console.log('failed to SetNewContentMessageRead ', newContentErr.message);
                                    }
                                }}
                            />,
                            { doNotShowInInternalPopupsOnlyMode: true }
                        );
                    } else if (parsed && parsed.type === 'daily-reward') {
                        const gemsObject = {
                            gemsUpdateId: nextGemsUpdateId(),
                            amount:parsed.total
                        };

                        addPopup(<GemCreditPopup
                            value={parsed.amount}
                            gemsObject={gemsObject}
                            title="Daily Gems"
                            message="Thanks for logging in! You've Earned"
                            buttonText="Got It!"
                        />, { doNotShowInInternalPopupsOnlyMode: true });

                        dispatch(SetGemsAction(parsed.total));
                    } else if (parsed?.type === 'nudge') {
                        const template = getTranslationTemplate(parsed.translationType);
                        dispatch(ShowNotificationAction(
                            translate(template.title, TRANS_NAMESPACES.UI, parsed.data),
                            translate(template.summary, TRANS_NAMESPACES.UI, parsed.data)
                        ));
                    } else if (parsed?.type === MESSAGE_TYPES.GAME_STATE_SAVED) {
                        dispatch(SaveStateAction(parsed.data));
                    } else if (resp.scriptData.type === MESSAGE_TYPES.NEW_FOLLOWER) {
                        dispatch(FetchMessageCountActionSuccess(1));
                    } else if (resp.scriptData.type === 'in_app_notification') {
                        const template = getTranslationTemplate(resp.scriptData.translationType);
                        dispatch(ShowNotificationAction(
                            translate(template.title, TRANS_NAMESPACES.UI, parsed),
                            translate(template.summary, TRANS_NAMESPACES.UI, parsed)
                        ));
                    } else if (resp.scriptData.type === MESSAGE_TYPES.PODIUM_UPDATE) {
                        const template = getTranslationTemplate(resp.scriptData.type);
                        dispatch(FetchMessageCountActionSuccess(1));
                        dispatch(ShowNotificationAction(
                            translate(template.title, TRANS_NAMESPACES.UI, "Podium Steal!"),
                            translate(template.summary, TRANS_NAMESPACES.UI, parsed.data.contentObject.title)
                        ));
                    } else if (resp.scriptData.type === MESSAGE_TYPES.SOCIAL_UPDATE) {
                        const template = getTranslationTemplate(resp.scriptData.type);
                        dispatch(FetchMessageCountActionSuccess(1));
                        dispatch(ShowNotificationAction(
                            translate(template.title, TRANS_NAMESPACES.UI, "Leaderboard Overtake!"),
                            translate(template.summary, TRANS_NAMESPACES.UI, parsed.data.contentObject.title)
                        ));
                    } else if (resp.scriptData.type === 'FreeTierUpdate') {
                        if (parsed.freeTierObject) {
                            dispatch({ type: UPDATE_FREE_TIER_INFORMATION, payload: parsed.freeTierObject});
                        }
                    } else if (resp.scriptData.type === 'reward') {
                        onRewardMessage(dispatch, parsed);
                    } else if (resp.scriptData.type === MESSAGE_TYPES.GIANT_SLAYER_TAUNT) {
                        const senderUserData = get(parsed.data, 'giant', '');
                        if (senderUserData) {
                            dispatch(ShowNotificationAction(
                                withBadWordMask(senderUserData.displayName),
                                "has created a giant slayer",
                                'giant-slayer',
                                senderUserData
                            ));
                        }
                    } else if (resp.scriptData.type === MESSAGE_TYPES.GIANT_SLAYER_RECRUIT) {
                        const senderUserData = get(parsed.data, 'slayer', '');
                        if (senderUserData) {
                            dispatch(ShowNotificationAction(
                                withBadWordMask(senderUserData.displayName),
                                "has joined a giant slayer",
                                'giant-slayer',
                                senderUserData
                            ));
                        }
                    } else if (resp.scriptData.type === MESSAGE_TYPES.GIANT_SLAYER_ENDED) {
                        dispatch(ShowNotificationAction(
                            "Giant Slayer Battle Ended!",
                            "Check challenge history for details",
                            'giant-slayer'
                        ));
                    } else if (resp.scriptData.type === MESSAGE_TYPES.GIANT_SLAYER_ABANDONED) {
                        dispatch(ShowNotificationAction(
                            "Giant Slayer Failed To Start",
                            "Please try again to set a score",
                            'giant-slayer'
                        ));
                    } else if (parsed && parsed.type === 'logout') {
                        const authToVerify = parsed.authToken;
                        const data = readRestUserData();
                        // if we use the same accessToken - clear local storage and do restart app
                        if (data && authToVerify === data.authToken) {
                            clearLoginCredentials();
                            clearAll('logout');
                            window.location.href = window.config.REACT_APP_URL;
                        }
                    } else if (parsed && parsed.type === 'accountDeleted') {
                        clearLoginCredentials();
                        clearAll('accountDeleted');
                        window.location.href = window.config.REACT_APP_URL;
                    } else {
                        if (parsed?.error) {
                            webSocketErrorHandling(JSON.parse(parsed.error), dispatch, getState);
                        }
                    }
                    break;
                }
                case '.NewHighScoreMessage':
                    const parsed = JSON.parse(resp.scriptData.data);
                    const gameId = parsed.leaderboardData.game_uuid;
                    onLeaderboardUpdateMessage(dispatch, gameId);
                    break;
                default:
                    break;
            }
        });
    };
};

export const APP_INIT_SUCCESS_ACTION = 'App Init Success';
export const AppInitActionSuccess = () => {
    return {type: APP_INIT_SUCCESS_ACTION};
};

export const SAVE_ROUTE = 'Saving Route Before Rerouting';
export const SaveRoute = (route, params = {}, search = {}) => {
    const payload = {
        route,
        params,
        search
    };
    return {type: SAVE_ROUTE, payload};
};

export const SET_WEBVIEW_STATE = 'Set Webview State Action';
export const SetWebviewState = (payload) => {
    return {type: SET_WEBVIEW_STATE, payload};
};

export const ON_WEBVIEW_SIZE_CHANGE = 'ON_WEBVIEW_SIZE_CHANGE';
export const OnWebViewSizeChange = (payload) => {
    return {type: ON_WEBVIEW_SIZE_CHANGE, payload};
};

export const SET_GEMS_ACTION = 'Set Gems Action';
export const SetGemsAction = (gems) => {
    return {type: SET_GEMS_ACTION, gems};
};

export const SET_EXP_ACTION = 'Set Exp Action';
export const SetExpAction = (expObject) => {
    return {type: SET_EXP_ACTION, expObject};
};

export const SET_SELECTED_LIST_INDEX = 'setSelectedListIndex';
export const setSelectedListIndex = (index) => {
    return {type: SET_SELECTED_LIST_INDEX, index};
};

export const SET_SELECTED_TILE_ID = 'setSelectedTileId';
export const setSelectedTileId = (tileId) => {
    return {type: SET_SELECTED_TILE_ID, tileId};
};

export const SET_CONFIG = 'SET_CONFIG';
export const setConfig = (config) => {
    return (dispatch) => {
        const {versions, remotes = []} = config;
        deviceInfo.setVersions(versions);
        deviceInfo.checkVersions();
        const justNames = remotes.map((remote) => remote.name);
        sendRemoteUpdateList(justNames);

        dispatch(SetConnectionTesterSupported(featureInfo.isSupported(FEATURES.CONNECTION_TESTER)));
        dispatch({type: SET_CONFIG, config});
    }
};

export const SET_CURRENT_REGION = 'SET_CURRENT_REGION';
export const setCurrentRegion = (region) => {
    return (dispatch) => {
        dispatch({type: SET_CURRENT_REGION, region});
        configureLogging(region);
    }
};

export const SET_FREE_TIER_INFO_SHOWN = 'SET_FREE_TIER_INFO_SHOWN';

export const setFreeTierInformationShown = () => {
    return (dispatch) => {
        dispatch({ type: SET_FREE_TIER_INFO_SHOWN });
        antstreamService
            .setFreeTiershown()
        navigateToLocation(ROUTES.FREE_TIER_INFORMATION,{ focusElement: externalFocusableFreeTierComponent.BACK_BUTTON})

    }
};

export const SET_PAYMENT_PAST_DUE_POPUP_SHOWN = 'SET_PAYMENT_PAST_DUE_POPUP_SHOWN';

export const setPaymentPastDuePopupShown = () => {
    return (dispatch) => {
        dispatch({ type: SET_PAYMENT_PAST_DUE_POPUP_SHOWN });
    }
};

export const FETCH_COMMS_MESSAGES = 'FETCH_COMMS_MESSAGES';

export const fetchCommsMessages = (userId) => {
    return (dispatch) => {
        dispatch({ type: FETCH_COMMS_MESSAGES });

        const { authToken } = readRestUserData() || {};
        if (!authToken) handleNoAuthTokenIssue(FETCH_COMMS_MESSAGES);

            const params = { headers: { Authorization: 'Bearer ' + authToken } };

            antstreamAPIService.messages.newContentList(params).then(({ data }) => {
                if (!data || !data.result) throw new Error('Something went wrong');
                if (data.error) throw new Error(data.error);
            }).catch(catchErr => {
                console.error('Failed to read new content message: ', catchErr);
            });

    }
};

export const ADD_VIDEO_GEMS = 'ADD_VIDEO_GEMS';

export const addVideoGems = (eventType) => {
	return (dispatch) => {
		dispatch({ type: ADD_VIDEO_GEMS });

        const { authToken } = readRestUserData() || {};
        if (!authToken) handleNoAuthTokenIssue(ADD_VIDEO_GEMS);

            const params = {
                body: { eventType },
                headers: { Authorization: 'Bearer ' + authToken }
            };
            antstreamAPIService.awardGift.awardGiftCreate(params).then(({ data }) => {
                if (!data || data.result !== 'OK') throw new Error('Something went wrong');
                if (data.error || data.Error) throw new Error(data.error || data.Error);
            }).catch(catchErr => {
                console.error('Failed to award gift: ', catchErr);
            });

	}
};

export const openQuitPopup = (onCancelClicked) => {
    addPopup(
        <QuitGamePopup
            message="This will close the app. Are you sure?"
            onOkClicked={()=>quitApp()}
            onCancelClicked={() => onCancelClicked()}
        />,
        {type:TYPE_QUIT}
    );
};

export const getGermanyAgeIcon = (currentGame) => {
    if(!currentGame || !currentGame.tags) return null;
    let germanyAgeIcon = null;
    Object.keys(germanyAgeIcons).forEach(key=>{
        if(currentGame.tags.includes(key)){
            germanyAgeIcon = germanyAgeIcons[key];
        }
    });
    return germanyAgeIcon;
};

export const startPaymentProcess = (showPopUp = false) => {
    if(deviceInfo.isDeviceIphone())
    {
        startNativePayment(APPLE_IOS_SKU);
    }
    else if(isDeviceWithNativePayments()) {
        navigateToLocation(ROUTES.PAYMENT_SELECTION);
    } else {
        handleNonNativePaymentProcess(showPopUp)
    }
};

const redirectToAccountsPage = () => {
    const url = antstreamService.getPaymentUrl();

    openUrlOnDefaultBrowser(url);
}

const handleNonNativePaymentProcess = (showPopUp = false) => {
    if(showPopUp) {
        addPopup(
            <GenericPopup
                title="Subscription required!"
                message="To enjoy our huge 1000+ games library, solo or community Vs challenges and compete in our upcoming exclusive tournaments - please continue to the website where you can add your payment information."
                messageSize={MESSAGE_SIZE.MEDIUM}
                cancelButtonLabel="Stay in Antstream"
                okButtonLabel="Subscribe now"
                buttonType={TYPE_HAS_TWO_BUTTONS}
                onCancelClicked={()=> navigateBack()}
                onOkClicked={()=> {
                    if(deviceInfo.isWaysunDevice()) {
                        navigateToLocation(ROUTES.WAYSUN_PAYMENT);
                    } else if(deviceInfo.isTrueTvDevice()) {
                        navigateToLocation(ROUTES.TRUE_PAYMENT_SELECTION);
                    } else {
                        redirectToAccountsPage();
                    }
                }}
            />
        )
    } else {
        if(deviceInfo.storeType == STORE_TYPE_SAMSUNG_TV)
        {
            addPopup(<GenericPopup
                okButtonLabel={"Got it!"}
                title={"Subscribe To Antstream"}
                message={'To enjoy our huge 1000+ games library, solo or community Vs challenges and compete in our upcoming exclusive tournaments - go to https://www.antstream.com/samsung.'}
            />);
        }else{
            redirectToAccountsPage();
        }
    }
}
 
export const DEVICE_INFO_RECEIVED_ACTION = 'DEVICE_INFO_RECEIVED_ACTION';
export const deviceInfoReceivedAction = (deviceInfo) => {
    return {type: DEVICE_INFO_RECEIVED_ACTION, deviceInfo};
};

export const SET_AS_TOUCH_DEVICE = 'SET_AS_TOUCH_DEVICE';
export const setAsTouchDevice = () => {
    return {type: SET_AS_TOUCH_DEVICE};
};

export const ALLOW_GEM_POPUP_ACTION = 'ALLOW_GEM_POPUP_ACTION';
export const allowGemPopupAction = (value) => {
    return (dispatch) => {
        dispatch({ type: ALLOW_GEM_POPUP_ACTION, value });
    }
};

export const SET_INTERNET_CONNECTION_STATUS = 'SET_INTERNET_CONNECTION_STATUS';
export const SetInternetConnectionStatusAction = (payload) => {
    return {type: SET_INTERNET_CONNECTION_STATUS, payload};
};

export const SET_USER_ONLINE = 'Set User Online';
export const SetUserOnlineAction = (payload) => {
    return {type: SET_USER_ONLINE, payload};
};

export const GET_IP_STACK_OBJECT_SUCCESS = 'GET_IP_STACK_OBJECT_SUCCESS';
export const GetIpStackObjectSuccess = (payload) => ({
    type: GET_IP_STACK_OBJECT_SUCCESS, payload
});

export const SUBSCRIPTIONS_POPUP_COUNT_INCREMENT = 'SUBSCRIPTIONS_POPUP_COUNT_INCREMENT';
export const incrementSubscriptionsPopupCount = () => {
    return {type: SUBSCRIPTIONS_POPUP_COUNT_INCREMENT};
};
