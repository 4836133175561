import { ReactFontSizeByTextLength } from 'react-font-size-by-text-length';
import {Avatar, AVATAR_TYPES} from '../common/avatar/avatar.component';
import React, {Component} from 'react';
import {withBadWordMask} from "../../assets/lib/utils";

class PodiumItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selected: false
		};
	}

	onCustomFocus = (focus/*, nextElement*/) => {
		this.setState({selected:focus});
	};

	// onItemRef = (element) => { // TODO Commented out to remove the issue of control confusing navigation when navigating to and from the play button - needs a design change to be supported
	// 	inputmanager.onChildRef(element, "podiumItem-"+this.props.leaderBoardItem.rank, this.onCustomFocus, 'podium-container');
	// };

	onPodiumUserClicked = (event) => {
		event.preventDefault();
		if(this.props.onPodiumUserClicked) this.props.onPodiumUserClicked(this.props.leaderBoardItem);
	};

	render() {
		const {leaderBoardItem} = this.props;

		return (
			<div
				ref={this.onItemRef}
				className={`podium-item rank-${
					leaderBoardItem.rank
				} ${
					this.state.selected?'selected':''
				} ${
					leaderBoardItem.avatarType===AVATAR_TYPES.TWITCH?'twitch':''
				}`}
				key={leaderBoardItem.rank}
				onClick={this.onPodiumUserClicked}
			>
				<Avatar userData={leaderBoardItem} />
				<div className="name">
					<ReactFontSizeByTextLength changePerChar={4} startAtChar={12} endAtChar={15}>
						<>{withBadWordMask(leaderBoardItem.displayName)}</>
					</ReactFontSizeByTextLength>
				</div>
				<div className="score">{leaderBoardItem.score}</div>
			</div>
		);
	}
}

export default PodiumItem;