import React, { Component, Suspense } from 'react';
import { Provider } from 'react-redux';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { Offline, Online } from "react-detect-offline";
import { ROUTES, navigateToLocation, getRouteForDeepLink } from './app.router';
import { ListenApiWebsocket } from './app.actions';
import AudioManager from './app.audio';
import { Header } from './components/header/header.component';
import { Debugger } from './components/debugger/debugger.component';
import { Notifications } from './components/notifications/notifications.component';
import PopupComponent from './components/popup/popup.component';
import Rankup from './components/rankup/rankup.component';
import DialogBoxOverlay from './components/dialog-box/dialog-box-overlay.component';
import gamePad from './assets/lib/gamepad.lib';
import deviceInfo, { isDeviceIphoneX, STORE_TYPE_SAMSUNG_TV } from "./assets/lib/deviceInfo";
import './app.less';
import ErrorBoundary from './components/common/error-boundary/error-boundary.component';
import { createObserver } from './assets/lib/intersectionObserver';
import { getInternetConnectionStatus, getThemeClass } from "./app.selectors";
import inputmanager from "./assets/lib/inputmanager";
import { Loader } from "./components/common/loader/loader.component";

import Elastic, {LOG_LEVEL} from './assets/lib/elastic.lib';
import {SetGameQuit} from './components/game-details/game-details.actions';
import {addPopup} from './components/popup/popup.component';
import {getRunningSessionId} from './app.selectors';
import GenericPopup from './components/popup/generic-popup/generic-popup.component';

import PwaInstallPopupIOS from 'react-pwa-install-ios';
import PWAPopupAndroid from './components/pwa-popup-android/pwa-popup-android.component';
import DeviceInfo from './assets/lib/deviceInfo';
import { versionGreaterThanOrEqual } from './assets/lib/utils';
import AllRoutes from './routes';
import { store, history } from './configureStore';
import { ConnectedRouter } from 'connected-react-router';

// eslint-disable-next-line no-unused-vars
gsap.registerPlugin(ScrollToPlugin);
// const plugins = [ScrollToPlugin]; // do not remove this because it needs for tweenlite

class App extends Component {
	constructor(props) {
		super(props);
		localStorage.setItem("baseUrl", `${window.location.pathname}`)

		console.log('[TEMP log]: APP_COMPONENT_FILE_LOADED'); // todo remove this

		this.state = {
			isOnline: true,
			themeClass: null,
			portraitPopup: false,
		}

		if (isDeviceIphoneX()) {
			let viewPortMeta = document.querySelector('meta[name="viewport"]');
			if (viewPortMeta === null) {
				viewPortMeta = document.createElement("meta");
				viewPortMeta.setAttribute("name", "viewport");
				document.head.appendChild(viewPortMeta);

				viewPortMeta = document.querySelector('meta[name="viewport"]');
			}
			viewPortMeta.setAttribute('content', "width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, viewport-fit=cover");
			document.body.classList.add('iPhoneX');
		}
	}

	handleResize = () => {
	}

	componentDidMount() {
		const search = store.getState().router.location.search;
		const pathname = store.getState().router.location.pathname;

		navigateToLocation(pathname, { hash: window.location.hash }, search);

		store.dispatch(ListenApiWebsocket());

		this.unsubscribeInputManager = store.subscribe(() => inputmanager.onStoreUpdate(store));
		this.unsubscribeGamepad = store.subscribe(() => gamePad.onStoreUpdate(store));
		this.unsubscribeAudioManager = store.subscribe(() => AudioManager.onStoreUpdate(store));
		this.unsubscribeDeviceInfo = store.subscribe(() => this.onDeviceInfo(store));
		this.unsubscribeOnlineMode = store.subscribe(() => this.onChangeOnlineMode(store));

		// Disable mouse-pointer mode on Xbox One
		navigator && (navigator.gamepadInputEmulation = "gamepad");

		createObserver();
		//window.addEventListener('resize', this.handleResize);
		//this.handleResize();

		if (deviceInfo.storeType == STORE_TYPE_SAMSUNG_TV) {
			window.addEventListener('appcontrol', this.samsungDeepLink);
			window.addEventListener('offline', this.showReconnectPopup);
			this.samsungDeepLink();

			const script = document.createElement('script');
			script.src = '$WEBAPIS/webapis/webapis.js';
			script.async = false;
			document.body.appendChild(script);
		}
	}

	componentWillUnmount() {
		this.unsubscribeInputManager();
		this.unsubscribeGamepad();
		this.unsubscribeAudioManager();
		this.unsubscribeDeviceInfo();
		this.unsubscribeOnlineMode();
		//window.removeEventListener('resize', this.handleResize);
	}

	getMainContainerStyles = () => {
		const styles = {};

		// const { safeAreaVertical, safeAreaHorizontal } = deviceInfo;
		// if (safeAreaVertical && safeAreaVertical !== 1) {
		// 	styles.paddingTop = (((1 - safeAreaVertical) * 100) / 2) +  'vh';
		// 	styles.height = (100 - (((1 - safeAreaVertical) * 100) / 2)) + '%';
		// 	styles.minHeight = (100 - (((1 - safeAreaVertical) * 100) / 2)) + 'vh';
		// }
		// if (safeAreaHorizontal && safeAreaHorizontal !== 1) {
		// 	styles.paddingLeft = (((1 - safeAreaHorizontal) * 100) / 2) +  'vh';
		// 	styles.paddingRight = (((1 - safeAreaHorizontal) * 100) / 2) +  'vh';
		// }

		return styles;
	};

	showReconnectPopup() {
		if (this.isPopupActive) return;
		this.isPopupActive = true;
		const popupContent = {
			okButtonLabel: "Retry",
			title: "Network Error",
			message: "There is a problem with your network connection",
		}
		addPopup(<GenericPopup
			{...popupContent}
			onOkClicked={() => {
				const currentPath = store.getState().routing.currentRoute.path;
				if (currentPath === ROUTES.IN_GAME_MENU.path || currentPath.includes(ROUTES.HOW_TO_PLAY.path)) {
					const sessionId = getRunningSessionId(store.getState());
					store.dispatch(SetGameQuit(sessionId));
				}
				navigateToLocation(ROUTES.HOMEPAGE);
				this.isPopupActive = false;
			}}
		/>, {}, { force: true });
	};

	 samsungDeepLink = () =>{
		const { application } = require('tizen-common-web');
		var requestedAppControl = application.getCurrentApplication().getRequestedAppControl(); 
		var appControlData;
		var actionData; 
	
		 if (requestedAppControl && requestedAppControl.appControl) {
			 appControlData = requestedAppControl.appControl.data;

			 for (var i = 0; i < appControlData.length; i++) {
				 if (appControlData[i].key == 'PAYLOAD') {
					//actionData = JSON.parse(appControlData[i].value[0]).values;
					actionData = appControlData[i].value[0];
					 const routeDetails = getRouteForDeepLink(actionData);
					 console.log(routeDetails);
					 Elastic.logDirect(LOG_LEVEL.INFO, "Samsung Deeplink accepted. Route is " + JSON.stringify(routeDetails));

					 navigateToLocation(routeDetails.route, routeDetails.params);
				 }
			 }
		 } else {
			 console.log("no req app control");
		 }
	 }

	onChangeOnlineMode(store) {
		const isOnlineOnNative = getInternetConnectionStatus(store.getState());

		if (this.state.isOnline === isOnlineOnNative) return;

		this.setState({ isOnline: isOnlineOnNative });
	}

	onDeviceInfo(store) {
		const themeClass = getThemeClass(store.getState());

		this.setState({
			themeClass
		})
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		return this.state.themeClass !== nextState.themeClass
			|| this.state.isOnline !== nextState.isOnline;
	}

	shouldDisplayOfflineView() {
		if (!DeviceInfo.isRunningOnNativeClientV2()) return true;
		const isModernNative = versionGreaterThanOrEqual(DeviceInfo.version, '3.1.0');

		return !isModernNative;
	}

	renderOnlineView() {
		const { themeClass } = this.state;
		const dropShadowSupport = DeviceInfo.isCssFilterDropShadowSupported() ? 'css-filter-drop-shadow' : '';
		const xboxPlatform = (DeviceInfo.isXboxPlatform() || DeviceInfo.isPlayStationPlatform()) ? 'xbox-platform' : '';
		const psPlatform = DeviceInfo.isPlayStationPlatform() ? 'playstation-platform' : '';
		const ultralightClass = DeviceInfo.isWebviewUltralight() ? 'webview-ultralight' : '';
		const safeAreaClass = DeviceInfo.isSafeAreaTurnedOn()
			? 'safe-area-turned-on' : '';

		return (
			<Provider store={store}>
				<ConnectedRouter history={history}>
				<Suspense fallback={<Loader loading={true} />}>
					<div id="input-manager-locked" />
					<div className={`main ${themeClass ? themeClass : ''} ${dropShadowSupport} ${xboxPlatform} ${ultralightClass} ${safeAreaClass} ${psPlatform}`}>
						<DialogBoxOverlay />
						<PopupComponent />
						<Rankup />
						<Debugger />
						<Notifications />
						<div style={this.getMainContainerStyles()} className="app-main-container" id="app-main-container">
							<Header />
							<AllRoutes />
						</div>
						{deviceInfo.isBrowserOnIphone() && <>
							<PwaInstallPopupIOS
								delay={3}
								// force={true} // (for debugging) force the display on any client
							>
								<div className="pwa-install-popup-ios-content">
									<div className="left">
										<img className="appIcon" src="icon.svg" />
									</div>
									<div className="right">
										<div>To launch Antstream app from your Home Screen</div>
										<div>1- Click on Share menu <span className="safari-share-icon"></span></div>
										<div>2- Select 'Add to Home Screen'</div>
									</div>
								</div>
							</PwaInstallPopupIOS>
						</>}
						<PWAPopupAndroid />
					</div>
				</Suspense>
				</ConnectedRouter>
			</Provider>
		)
	}

	renderOfflineView() {
		const { themeClass } = this.state;
		const xboxPlatform = (DeviceInfo.isXboxPlatform() || DeviceInfo.isPlayStationPlatform()) ? 'xbox-platform' : '';

		return (
			<div className={`main ${themeClass ? themeClass : ''} ${xboxPlatform}`}>
				<div className="app-main-container">
					<div className="vertical-center connection-lost-screen">
						<h1 className='connection-lost-screen__h1'>Internet Connection Error</h1>
						<h2 className='connection-lost-screen__h2'>
							We are unable to connect you to Antstream. Please verify your Internet connection.
						</h2>
					</div>
				</div>
			</div>
		)
	}

	render() {
		if (!this.state.isOnline && this.shouldDisplayOfflineView()) {
			return this.renderOfflineView();
		}

		return (
			<ErrorBoundary>
				{(!deviceInfo.isDeviceIphone()) && <>
					{this.renderOnlineView()}</>}

				{(deviceInfo.isDeviceIphone()) && 
					<>
						<Offline>
							{this.renderOfflineView()}
						</Offline>
						<Online>{this.renderOnlineView()}</Online>
					</>}
			</ErrorBoundary>
		);
	}
}

export default App;
